import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { CustomTooltip } from "../../_components";

class Grid extends Component {
    colors = ["#CCD3EA", "#8FA2D4", "#4471C4", "#134AAC", "#1D448B"];
    ref = null;
    constructor(props) {
        super(props);
        this.state = {
        }
        this.ref = React.createRef();
    }

    renderSummaryHeading() {
        const { t, question, index, noOfParticipants, totalConsumers } = this.props;
        return (
            <div className="d-block heading">
                <div className="d-inline-block align-top heading-number" style={{ backgroundColor: '#7F869F' }}>{index}</div>
                <div className="d-inline-block align-top heading-text">
                    <label>{question}</label>
                    <p>{totalConsumers} {t('out of')} {noOfParticipants} {t("answered this question")}</p>
                </div>
            </div>
        );
    }

    renderSummaryGridSamples() {
        const { summ, index } = this.props;
        const retData = [];
        for (let i = 0; i < summ.length; i++) {
            const data = summ[i];
            retData.push(
                <div key={`${i}-gird-sample-name-${index}`} className="d-inline-block sample-text">
                    <span className="d-inline-block" style={{ backgroundColor: this.colors[i] }}></span>
                    <label>{data.sample}</label>
                </div>
            );
        }
        return retData;
    }

    renderSummaryLines() {
        const retData = [];
        const { summ, index, totalConsumers, showPercentage } = this.props;
        let opts = [];
        let showTooltip = summ.length > 3;
        for (let i = 0; i < summ.length; i++) {
            let row = summ[i];
            let graphLines = [];
            for (let j = 0; j < row.ans.length; j++) {
                const ans = summ[i].ans[j];
                const percentage = parseFloat((ans.cnt / totalConsumers) * 100).toFixed(1);
                graphLines.push(
                    <div key={`${index}-grid-graph-lines-${j}`} className="d-flex align-content-end justify-content-center flex-wrap graph">
                        <div className="d-flex align-content-end flex-wrap graph-inner">
                            {(showPercentage || !showTooltip) && <label>{parseInt(percentage) !== 0 ? `${percentage}%` : ""}</label>}
                            {
                                showTooltip &&
                                <CustomTooltip placement="top" title={parseInt(percentage) !== 0 ? `${percentage}%` : ""}>
                                    <span style={{ backgroundColor: this.colors[j], height: percentage + '%' }}>
                                        {percentage}%
                                    </span>
                                </CustomTooltip>
                            }
                            {
                                !showTooltip &&
                                <span style={{ backgroundColor: this.colors[j], height: percentage + '%' }}>
                                    {percentage}%
                                </span>
                            }
                        </div>
                    </div>
                )
            }
            retData.push(
                <div key={`${index}-multi-choice-all-sample-graph-${i}`} className="col" style={{ paddingLeft: '7px', paddingRight: '7px' }}>
                    <div className="d-flex align-content-end justify-content-center graph-lines">
                        {graphLines}
                    </div>
                </div>
            )
        }
        return retData;
    };

    renderSummaryTexts() {
        const { index, opts } = this.props;
        let opt = [];
        const retData = [];
        for (let i = 0; i < opts.samples.length; i++) {
            const data = opts.samples[i];
            retData.push(
                <div key={`${index}-grid-sample-ans-${i}`} className="col" style={{ paddingLeft: '7px', paddingRight: '7px' }}>
                    <p>{data}</p>
                </div>
            );

        }
        return retData;
    };

    getChartHeight = () => {
        const gridHeight = this.ref.current.clientHeight;
        return gridHeight;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="question-container" ref={this.ref}>
                {this.renderSummaryHeading()}
                <div className="d-block question-inner">
                    <div className="d-block grid-graph-container">
                        <div className="d-block sample-text-blocks">
                            {this.renderSummaryGridSamples()}
                        </div>
                        <div className="d-block grid-graph-scrollbar line-scale-graph-scrollbar" style={{ height: '275px', paddingLeft: '0px' }}>
                            <div className="d-flex align-content-end flex-wrap grid-graph" style={{
                                backgroundColor: 'rgba(230, 232, 239, 0.63)',
                                width: '100%'
                            }}>
                                <div class="row" style={{ width: '100%', marginRight: '0px', marginLeft: '0px' }}>
                                    {this.renderSummaryLines()}
                                </div>
                            </div>
                            <div className="d-flex grid-graph-text" style={{ width: '100%' }}>
                                <div className="row" style={{ width: '100%', marginLeft: '0px', marginRight: '0px' }}>
                                    {this.renderSummaryTexts()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const translatedGrid = withTranslation('', { withRef: true })(Grid);
export { translatedGrid as Grid };