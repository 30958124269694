import React from 'react';

const Login = React.lazy(() => import('../PreLogin/Login'));
const Register = React.lazy(() => import('../PreLogin/Register'));
const VerifyEmail = React.lazy(() => import('../PreLogin/VerifyEmail'));
const ForgotPassword = React.lazy(() => import('../PreLogin/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../PreLogin/ResetPassword'));

const loginRoutes = [
    { path: '/prelogin/login', exact: true, name: 'Login', component: Login },
    { path: '/prelogin/register', exact: true, name: 'Register', component: Register },
    { path: '/prelogin/register/:token', exact: true, name: 'Register', component: Register },
    { path: '/prelogin/verifyemail', exact: true, name: 'Verify Email', component: VerifyEmail },
    { path: '/prelogin/forgotpassword', exact: true, name: 'Forgot Password', component: ForgotPassword },
    { path: '/prelogin/resetpassword', exact: true, name: 'Reset Password', component: ResetPassword },
];

export default loginRoutes;