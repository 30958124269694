import { testConstants, authConstants } from '../_constants';
import { testServices } from '../_services';
import { alert, commonFunctions } from '../_utilities';

export const testActions = {
    createTest,
    getAllTest,
    getIndividualTest,
    getDietaryTags,
    getLocation,
    getFamilySituation,
    setTestCreationStatus,
    deleteTest,
    getTestsByDate,
    getTestGallery,
    uploadGalleryImage,
    getEducationLevel,
    getIncomeLevel,
    editTestImage,
    downloadParticipantList,
    getAllQuestionnaire,
    getTestsWithoutQuestionnaire,
    getAllergensList
};

function createTest(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.CREATE_TEST_REQUEST,
            data: null
        }));
        testServices.createTest(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.CREATE_TEST_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.CREATE_TEST_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.CREATE_TEST_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getAllTest(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_ALL_TEST_REQUEST,
            data: null
        }));
        testServices.getAllTest(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_ALL_TEST_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_ALL_TEST_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_ALL_TEST_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getAllQuestionnaire(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_ALL_QUESTIONNAIRE_REQUEST,
            data: null
        }));
        testServices.getAllQuestionnaire(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_ALL_QUESTIONNAIRE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_ALL_QUESTIONNAIRE_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_ALL_QUESTIONNAIRE_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function downloadParticipantList(data){
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.DOWNLOAD_PARTICIPANT_LIST_REQUEST,
            data: null
        }));
        testServices.downloadParticipantList(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.DOWNLOAD_PARTICIPANT_LIST_SUCCESS,
                            data: response
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.DOWNLOAD_PARTICIPANT_LIST_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.DOWNLOAD_PARTICIPANT_LIST_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getIndividualTest(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_INDIVIDUAL_TEST_REQUEST,
            data: null
        }));
        testServices.getIndividualTest(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_INDIVIDUAL_TEST_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_INDIVIDUAL_TEST_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_INDIVIDUAL_TEST_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getDietaryTags() {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_DIETARY_TAGS_REQUEST,
            data: null
        }));
        testServices.getDietaryTags()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_DIETARY_TAGS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_DIETARY_TAGS_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_DIETARY_TAGS_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getAllergensList() {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_ALERGENS_LIST_REQUEST,
            data: null
        }));
        testServices.getAllergensList()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_ALERGENS_LIST_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_ALERGENS_LIST_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_ALERGENS_LIST_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getFamilySituation() {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_FAMILY_SITUATION_REQUEST,
            data: null
        }));
        testServices.getFamilySituation()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_FAMILY_SITUATION_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_FAMILY_SITUATION_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_FAMILY_SITUATION_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getEducationLevel() {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_EDUCATION_LEVEL_REQUEST,
            data: null
        }));
        testServices.getEducationLevel()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_EDUCATION_LEVEL_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_EDUCATION_LEVEL_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_EDUCATION_LEVEL_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getIncomeLevel() {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_INCOME_LEVEL_REQUEST,
            data: null
        }));
        testServices.getIncomeLevel()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_INCOME_LEVEL_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_INCOME_LEVEL_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_INCOME_LEVEL_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getLocation(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_LOCATION_REQUEST,
            data: null
        }));
        testServices.getLocation(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_LOCATION_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_LOCATION_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_LOCATION_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function deleteTest(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.DELETE_TEST_REQUEST,
            data: null
        }));
        testServices.deleteTest(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.DELETE_TEST_SUCCESS,
                            data: {
                                ...data,
                                ...response.data
                            }
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.DELETE_TEST_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.DELETE_TEST_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getTestsByDate(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_TESTS_BY_DATE_REQUEST,
            data: null
        }));
        testServices.getTestsByDate(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_TESTS_BY_DATE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_TESTS_BY_DATE_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_TESTS_BY_DATE_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getTestGallery(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.GET_TEST_GALLERY_REQUEST,
            data: null
        }));
        testServices.getTestGallery(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_TEST_GALLERY_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.GET_TEST_GALLERY_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.GET_TEST_GALLERY_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function uploadGalleryImage(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.UPLOAD_GALLERY_IMAGE_REQUEST,
            data: null
        }));
        testServices.uploadGalleryImage(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.UPLOAD_GALLERY_IMAGE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.UPLOAD_GALLERY_IMAGE_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.UPLOAD_GALLERY_IMAGE_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function editTestImage(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.EDIT_TEST_IMAGE_REQUEST,
            data: null
        }));
        testServices.editTestImage(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.EDIT_TEST_IMAGE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.EDIT_TEST_IMAGE_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.EDIT_TEST_IMAGE_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function setTestCreationStatus(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.SET_NEW_CREATED_TEST_STATUS,
            data: data
        }));
    };
}

function dispatchFunction(data) {
    if(data.data && data.data.code === 401){
        commonFunctions.onLogout();
        return {
            type: authConstants.USER_LOGOUT,
            data: null
        };
    }
    return {
        type: data.type,
        data: data.data
    };
}

function getTestsWithoutQuestionnaire(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: testConstants.TESTS_WITHOUT_QUESTIONNAIRE_REQUEST,
            data: null
        }));
        testServices.getTestsWithoutQuestionnaire(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: testConstants.TESTS_WITHOUT_QUESTIONNAIRE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: testConstants.TESTS_WITHOUT_QUESTIONNAIRE_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: testConstants.TESTS_WITHOUT_QUESTIONNAIRE_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}
