import { authConstants, questionnaireConstants } from '../_constants';
import { questionnaireServices } from '../_services';
import { alert, commonFunctions } from '../_utilities';

export const questionnaireAction = {
    addQuestionnaire,
    getIndividualQuestionnaire,
    getResponseOfQuestionnaire,
    getTestSummary,
    getTestSummaryForPrint,
    getSummaryForSession,
    getDownloadFile,
    getSummaryPDF
};

function addQuestionnaire(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: questionnaireConstants.ADD_QUESTIONNAIRE_REQUEST,
            data: null
        }));
        questionnaireServices.addQuestionnaire(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.ADD_QUESTIONNAIRE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.ADD_QUESTIONNAIRE_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: questionnaireConstants.ADD_QUESTIONNAIRE_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getIndividualQuestionnaire(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: questionnaireConstants.GET_INDIVIDUAL_QUESTIONNAIRE_REQUEST,
            data: null
        }));
        questionnaireServices.getIndividualQuestionnaire(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_INDIVIDUAL_QUESTIONNAIRE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_INDIVIDUAL_QUESTIONNAIRE_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: questionnaireConstants.GET_INDIVIDUAL_QUESTIONNAIRE_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getResponseOfQuestionnaire(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: questionnaireConstants.GET_RESPONSES_QUESTIONNAIRE_REQUEST,
            data: null
        }));
        questionnaireServices.getResponseOfQuestionnaire(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_RESPONSES_QUESTIONNAIRE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_RESPONSES_QUESTIONNAIRE_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: questionnaireConstants.GET_RESPONSES_QUESTIONNAIRE_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getTestSummary(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: questionnaireConstants.GET_TEST_SUMMARY_REQUEST,
            data: null
        }));
        questionnaireServices.getTestSummary(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_TEST_SUMMARY_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_TEST_SUMMARY_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: questionnaireConstants.GET_TEST_SUMMARY_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getTestSummaryForPrint(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: questionnaireConstants.GET_TEST_SUMMARY_PRINT_REQUEST,
            data: null
        }));
        questionnaireServices.getTestSummaryForPrint(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_TEST_SUMMARY_PRINT_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_TEST_SUMMARY_PRINT_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: questionnaireConstants.GET_TEST_SUMMARY_PRINT_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getSummaryForSession(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: questionnaireConstants.GET_TEST_SUMMARY_FOR_SESSION_REQUEST,
            data: null
        }));
        questionnaireServices.getSummaryForSession(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_TEST_SUMMARY_FOR_SESSION_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_TEST_SUMMARY_FOR_SESSION_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: questionnaireConstants.GET_TEST_SUMMARY_FOR_SESSION_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getDownloadFile(data){
    return dispatch => {
        dispatch(dispatchFunction({
            type: questionnaireConstants.GET_DOWNLOAD_REQUEST,
            data: null
        }));
        questionnaireServices.getDownloadFile(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_DOWNLOAD_SUCCESS,
                            data: response
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.GET_DOWNLOAD_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: questionnaireConstants.GET_DOWNLOAD_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getSummaryPDF(data){
    return dispatch => {
        dispatch(dispatchFunction({
            type: questionnaireConstants.PRINT_SUMMARY_REQUEST,
            data: null
        }));
        questionnaireServices.getSummaryPDF(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.PRINT_SUMMARY_SUCCESS,
                            data: response
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: questionnaireConstants.PRINT_SUMMARY_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: questionnaireConstants.PRINT_SUMMARY_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function dispatchFunction(data) {
    if (data.data && data.data.code === 401) {
        commonFunctions.onLogout();
        return {
            type: authConstants.USER_LOGOUT,
            data: null
        };
    }
    return {
        type: data.type,
        data: data.data
    };
}