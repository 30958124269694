import { authConstants, status } from '../_constants';
// import { localStorageEnc } from '../_utilities';

let user = localStorage.getItem('userData');
let initialState = {};
if (user) {
    user = JSON.parse(user);
    initialState = {
        user_login_status: status.SUCCESS,
        user
    };
}

export function auth(state = initialState, action) {
    switch (action.type) {
        case authConstants.USER_LOGOUT:
            return {
                ...state,
                user: null
            };
        case authConstants.USER_LOGIN_REQUEST:
            return {
                ...state,
                user_login_status: status.IN_PROGRESS,
                user: action.data
            };
        case authConstants.USER_LOGIN_SUCCESS:
            return {
                ...state,
                user_login_status: status.SUCCESS,
                user: action.data
            };
        case authConstants.USER_LOGIN_FAILURE:
            return {
                ...state,
                user_login_status: status.FAILURE,
                user: null
            };
        case authConstants.REGISTER_USER_REQUEST:
            return {
                ...state,
                register_status: status.IN_PROGRESS,
                registrationData: action.data
            };
        case authConstants.REGISTER_USER_SUCCESS:
            return {
                ...state,
                register_status: status.SUCCESS,
                registrationData: action.data
            };
        case authConstants.REGISTER_USER_FAILURE:
            return {
                ...state,
                register_status: status.FAILURE,
                registrationData: null
            };
        case authConstants.REGISTER_MEMBER_REQUEST:
            return {
                ...state,
                register_member_status: status.IN_PROGRESS,
                registerMemberData: action.data
            };
        case authConstants.REGISTER_MEMBER_SUCCESS:
            return {
                ...state,
                register_member_status: status.SUCCESS,
                registerMemberData: action.data,
                user: {
                    userDetails: action.data.user,
                    token: action.data.token
                }
            };
        case authConstants.REGISTER_MEMBER_FAILURE:
            return {
                ...state,
                register_member_status: status.FAILURE,
                registerMemberData: null
            };
        case authConstants.CHECK_EMAIL_REQUEST:
            return {
                ...state,
                check_email_status: status.IN_PROGRESS,
                checkEmailData: action.data
            };
        case authConstants.CHECK_EMAIL_SUCCESS:
            return {
                ...state,
                check_email_status: status.SUCCESS,
                checkEmailData: action.data
            };
        case authConstants.CHECK_EMAIL_FAILURE:
            return {
                ...state,
                check_email_status: status.FAILURE,
                checkEmailData: null
            };
        case authConstants.CHECK_COMPANY_REQUEST:
            return {
                ...state,
                check_company_status: status.IN_PROGRESS,
                checkCompanyData: action.data
            };
        case authConstants.CHECK_COMPANY_SUCCESS:
            return {
                ...state,
                check_company_status: status.SUCCESS,
                checkCompanyData: action.data
            };
        case authConstants.CHECK_COMPANY_FAILURE:
            return {
                ...state,
                check_company_status: status.FAILURE,
                checkCompanyData: null
            };
        case authConstants.VERIFY_EMAIL_REQUEST:
            return {
                ...state,
                verify_email_status: status.IN_PROGRESS,
                verifyEmailData: action.data
            };
        case authConstants.VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                verify_email_status: status.SUCCESS,
                verifyEmailData: action.data,
                user: action.data
            };
        case authConstants.VERIFY_EMAIL_FAILURE:
            return {
                ...state,
                verify_email_status: status.FAILURE,
                verifyEmailData: null
            };
        case authConstants.RESEND_EMAIL_REQUEST:
            return {
                ...state,
                resend_email_status: status.IN_PROGRESS,
                resendEmailData: action.data
            };
        case authConstants.RESEND_EMAIL_SUCCESS:
            return {
                ...state,
                resend_email_status: status.SUCCESS,
                resendEmailData: action.data
            };
        case authConstants.RESEND_EMAIL_FAILURE:
            return {
                ...state,
                resend_email_status: status.FAILURE,
                resendEmailData: null
            };
        case authConstants.FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                forgot_password_status: status.IN_PROGRESS,
                forgotPasswordData: action.data
            };
        case authConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgot_password_status: status.SUCCESS,
                forgotPasswordData: action.data
            };
        case authConstants.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                forgot_password_status: status.FAILURE,
                forgotPasswordData: null
            };
        case authConstants.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                reset_password_status: status.IN_PROGRESS,
                user: action.data
            };
        case authConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                reset_password_status: status.SUCCESS,
                user: action.data
            };
        case authConstants.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                reset_password_status: status.FAILURE,
                user: null
            };
        default:
            return state;
    }
}