import config from '../config';
import { commonFunctions } from "../_utilities";

export const testServices = {
    createTest,
    getAllTest,
    getIndividualTest,
    getDietaryTags,
    getAllergensList,
    getLocation,
    deleteTest,
    getTestsByDate,
    getTestGallery,
    uploadGalleryImage,
    getFamilySituation,
    getEducationLevel,
    getIncomeLevel,
    editTestImage,
    downloadParticipantList,
    getAllQuestionnaire,
    getTestsWithoutQuestionnaire
}

function createTest(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data));
    return fetch(`${config.apiUrl}/api/test`, requestOptions).then(response => response.json());
}

function getAllTest(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/test/all?skip=${data.skip}&limit=${data.limit}`, requestOptions).then(response => response.json());
}

function getAllQuestionnaire(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/questionnaires/all?skip=${data.skip}&limit=${data.limit}`, requestOptions).then(response => response.json());
}

function getIndividualTest(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/test/individual/${data.id}`, requestOptions).then(response => response.json());
}

function getFamilySituation(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.adminUrl}/family-situation`, requestOptions).then(response => response.json());
}

function getEducationLevel(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.adminUrl}/education`, requestOptions).then(response => response.json());
}

function getIncomeLevel(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.adminUrl}/income`, requestOptions).then(response => response.json());
}

function getLocation(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.adminUrl}/location/getlocation/${data.countryId}`, requestOptions).then(response => response.json());
}

function getDietaryTags() {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/dietarytags`, requestOptions).then(response => response.json());
}

function getAllergensList() {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/dietarytags/allergy-tag`, requestOptions).then(response => response.json());
}

function deleteTest(data) {
    const requestOptions = commonFunctions.getRequestOptions("DELETE", {}, null);
    return fetch(`${config.apiUrl}/api/test/${data.id}`, requestOptions).then(response => response.json());
}

function getTestsByDate(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/test/bydaterange?date=${data.date}`, requestOptions).then(response => response.json());
}

function getTestGallery() {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.adminUrl}/gallery`, requestOptions).then(response => response.json());
}

function uploadGalleryImage(data) {
    const formData = new FormData();
    formData.append("file", data.image);
    const requestOptions = commonFunctions.getRequestOptions("POST", {}, formData);
    return fetch(`${config.adminUrl}/gallery/test`, requestOptions).then(response => response.json());
}

function editTestImage(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("PATCH", extraHeaders, JSON.stringify({ testImage: data.testImage }));
    return fetch(`${config.apiUrl}/api/test/testimage/${data.testId}`, requestOptions).then(response => response.json());
}

function downloadParticipantList(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/test/export/participants/${data.testId}`, requestOptions);
}

function getTestsWithoutQuestionnaire(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/test/only-tests?skip=${data.skip}&limit=${data.limit}`, requestOptions).then(response => response.json());
}
