import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from "react-i18next";

class TextSummary extends Component {
    ref = null;
    scrollRef = null;
    constructor(props) {
        super(props);
        this.state = {};
        this.ref = React.createRef();
        this.scrollRef = React.createRef();
    }

    renderSummaryHeading() {
        const { t, question, index, noOfParticipants, totalConsumers } = this.props;
        return (
            <div className="d-block heading">
                <div className="d-inline-block align-top heading-number" style={{ backgroundColor: "#EFA21C" }}>{index}</div>
                <div className="d-inline-block align-top heading-text">
                    <label>{question}</label>
                    <p>{totalConsumers} {t('out of')} {noOfParticipants} {t("answered this question")}</p>
                </div>
            </div>
        );
    }

    renderSummaryTextInput() {
        const { icon, opts, summ, index } = this.props;
        const retData = [];
        if (summ && summ.length > 0) {
            for (let i = 0; i < summ.length; i++) {
                const data = summ[i];
                retData.push(
                    <div key={`${i}-textInputLength-${index}${i}`} className="d-block text-input-here">{data}</div>
                );
            }
        }
        return retData;
    }

    getChartHeight = () => {
        if (this.ref && this.ref.current && this.scrollRef && this.scrollRef.current) {
            const testSummaryHeight = this.ref.current.clientHeight + this.scrollRef.current.clientHeight;
            return testSummaryHeight;
        }
        return 0;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="d-block question-container" ref={this.ref}>
                {this.renderSummaryHeading()}
                <div className="d-block question-inner">
                    <div className="d-block input-here-graph-container">
                        <div className="d-block input-here-container">
                            <Scrollbars style={{ maxHeight: "100%", minHeight: "100%" }}>
                                <div className="inner-scroll-container" ref={this.scrollRef}>
                                    {this.renderSummaryTextInput()}
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const translatedTextSummary = withTranslation('', { withRef: true })(TextSummary);
export { translatedTextSummary as TextSummary };