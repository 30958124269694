import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

export class MaterialCustomTextBox extends Component {
    onChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };
    render() {
        const { containerClass, labelClass, inputClass, label, htmlFor, id, name, placeholder, value, icon, isValid, message, type, rowsMax, multiline } = this.props;
        return (
            <FormControl className={containerClass} error={!isValid}>
                <label className={labelClass} htmlFor={htmlFor}>{label}</label>
                <Input className={inputClass} id={id} name={name} value={value} placeholder={placeholder} onChange={this.onChange} rowsMax={rowsMax} multiline={multiline}/>
                {
                    !isValid &&
                    <FormHelperText>{message}</FormHelperText>
                }
            </FormControl>
        );
    }
}