import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

export const SingleSlider = withStyles({
    root: {
        color: '#5656B7',
        height: 5,
    },
    thumb: {
        height: 23,
        width: 23,
        marginTop: "-9px",
        marginLeft: "-12px",
        backgroundColor: '#DAE1F8',
        boxShadow: "3px 3px 10px #00000029",
        '&:focus, &:hover, &$active': {
            boxShadow: '3px 3px 10px #00000029',
        },
    },
    active: {},
    track: {
        height: 5,
        borderRadius: 3
    },
    rail: {
        height: 5,
        borderRadius: 3,
        color: "#1a9691",
        opacity: 1
    },
    valueLabel: {
        left: '-4px',
        fontFamily: "Open Sans",
        fontSize: "13px",
        fontWeight: 600,
        top: -17,
        '& *': {
            background: 'transparent',
            color: '#333951',
        },
    },
})(Slider);