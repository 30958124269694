import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../_routes/routes';
import '../_assets/scss/default-layout.scss';
import { Header, Sidebar } from '../PostLogin/_components';
import { localStorageEnc, GA } from "../_utilities";
import { eventActions, eventCategories } from '../_constants';

class DefaultLayout extends Component {
    constructor(props) {
        super(props);
        const { auth } = this.props;
        this.props.history.listen((location, action) => {
            if (auth && auth.user && auth.user.userDetails) {
                GA.dispatchGAEvent(eventCategories.USER, eventActions.PAGEVIEW, `organization=${auth.user.userDetails.organizationId.name};id=${auth.user.userDetails._id};page=${location.pathname}`);
            }
        });
        if (auth && auth.user && auth.user.userDetails) {
            GA.dispatchGAEvent(eventCategories.USER, eventActions.PAGEVIEW, `organization=${auth.user.userDetails.organizationId.name};id=${auth.user.userDetails._id}`);
        }
    }

    isUserLoggedIn = () => {
        return localStorage.getItem("userData") != null;
    };

    createRoutes = () => {
        const retData = routes.map((route, index) => {
            return (route.component) ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                        <route.component {...props} />
                    )} />
            ) : (null);
        });
        return retData;
    };

    render() {
        const isLoggedIn = this.isUserLoggedIn();
        return (
            <div className="theme-white fixed-header fixed-sidebar main-container default-main-container">
                <Header {...this.props} />
                <div className="main">
                    <Sidebar {...this.props} />
                    <div className="content-container">
                        {
                            isLoggedIn &&
                            this.createRoutes()
                        }
                        {
                            !isLoggedIn &&
                            <Redirect
                                to={{ pathname: "/prelogin/login", state: { from: this.props.location } }}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const connectedDefaultLayout = connect(mapStateToProps)(DefaultLayout);
export { connectedDefaultLayout as DefaultLayout };
