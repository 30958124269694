import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from './../../_assets/images/logo-header.svg';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Dropdown from 'react-bootstrap/Dropdown';
import { withTranslation } from "react-i18next";
import i18next from 'i18next';
import './../../_assets/scss/header.scss';
import { authActions } from '../../_actions';
import janmayenflag from './../../_assets/images/janmayenflag.png';
import unitedflag from './../../_assets/images/unitedflag.jpg';
import { Link } from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props);
        let language = localStorage.getItem("language");
        if (!language) {
            language = "en";
            localStorage.setItem("language", "en");
        }
        i18next.changeLanguage(language);
        this.state = {
            language
        };
    }

    onClickLogout = (e) => {
        e.preventDefault();
        this.props.dispatch(authActions.logOut());
        this.props.history.push('/prelogin/login');
    };

    onClickChangeLanguage = (language) => {
        i18next.changeLanguage(language);
        this.setState({
            language
        });
        localStorage.setItem("language", language);
    };

    render() {
        const { user, t } = this.props;
        const { language } = this.state;
        let initial = "";
        let name = "";
        if (user) {
            name = user.userDetails.name;
            if (name.split(" ").length > 1) {
                name = name.split(" ")[0];
            }
            initial = name[0];
        }
        return (
            <div className="header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-1 col-lg-2 col-md-2 col-sm-12" style={{ zIndex: 1 }}>
                            <div className="logo d-inline-block">
                                <Link to='/postlogin/dashboard'>
                                    <img src={logo} alt="" />
                                    <label className="logo-text">TRYIO</label>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-6 col-md-6 col-sm-12">
                            <form className="search-container form-inline d-flex justify-content-center md-form form-sm mt-0 position-relative">
                                <button type="button" className="position-absolute btn search-btn">
                                    <SearchIcon />
                                </button>
                                <input className="form-control form-control-sm w-100 text-center" type="text" placeholder={t("Search for or type a command")} />
                            </form>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                            <div className="float-right profile">
                                <div className="btn-group d-inline-block align-middle profile-dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {name}
                                            <span className="d-inline-block align-middle pt-1"><KeyboardArrowDownIcon /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#">{t("Get help")}</Dropdown.Item>
                                            <Dropdown.Item onClick={this.onClickLogout} href="#" className="logout">{t("Log out")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="rounded-circle d-inline-block align-middle text-center profile-image mr-3">
                                    <span>{initial}</span>
                                </div>
                                <div className="btn-group d-inline-block align-middle flags-dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {
                                                language === "no" && <img src={janmayenflag} alt="" />
                                            }
                                            {
                                                language === "en" && <img src={unitedflag} alt="" />
                                            }
                                            <span className="d-inline-block align-middle pt-1 pl-1"><KeyboardArrowDownIcon /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={e => this.onClickChangeLanguage('no')}><img src={janmayenflag} alt="" /></Dropdown.Item>
                                            <Dropdown.Item onClick={e => this.onClickChangeLanguage('en')}><img src={unitedflag} alt="" /></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    };
}

const connectedHeader = withTranslation()(connect(mapStateToProps)(Header));
export { connectedHeader as Header };
