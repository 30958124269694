import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from "react-i18next";
import './../../_assets/scss/test-summary.scss';

class TestSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summary: [],
            changedIndex: -1
        };
        this.scrollRef = React.createRef();
        this.changedElement = React.createRef();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.summary !== this.props.summary) {
            const sortedSummary = this.sortSummaryOnIndex(JSON.parse(this.props.summary));
            this.setState({
                summary: sortedSummary,
                changedIndex: this.props.changedIndex
            }, () => {
                if (this.changedElement.current) {
                    const index = this.changedElement.current.getAttribute("index");
                    const children = this.changedElement.current.parentElement.children;
                    let height = 0;
                    for (let i = 0; i < index; i++) {
                        if (children[i]) {
                            height += children[i].scrollHeight;
                        }
                    }
                    this.scrollRef.current.view.scroll({ behavior: 'smooth', top: height });
                }
            });
        }
    }

    sortSummaryOnIndex = (summary) => {
        summary.sort((a, b)=>{
            if(a.index < b.index){
                return -1;
            }
            if(a.index > b.index){
                return 1;
            }
            return 0;
        });
        return summary;
    };

    renderItem = (item) => {
        const { t } = this.props;
        const keys = Object.keys(item);
        const list = [];
        for (let j = 0; j < keys.length; j++) {
            if (item[keys[j]].isMultipleItems && item[keys[j]].value.length > 0) {
                const summaryValues = [];
                for (let k = 0; k < item[keys[j]].value.length; k++) {
                    let mS = item[keys[j]].value[k];
                    summaryValues.push(<label className="d-inline-block summary-value mb-1 mr-1">{t(mS + "")}</label>);
                }
                list.push(
                    <li key={`subrow-${j}`} className="d-block">
                        <label className="d-inline-block summary-label">{t(item[keys[j]].label)}:</label>
                        {summaryValues}
                    </li>
                );
            } else if (item[keys[j]].isImage && item[keys[j]].value) {
                list.push(
                    <li key={`subrow-${j}`} className="d-block" style={{ paddingRight: "8px" }}>
                        <img src={item[keys[j]].value} style={{ width: "100%", borderRadius: '8px' }} />
                    </li>
                );
            } else if (!item[keys[j]].isMultipleItems && item[keys[j]].value) {
                list.push(
                    <li key={`subrow-${j}`} className="d-block">
                        <label className="d-inline-block summary-label">{t(item[keys[j]].label)}:</label>
                        <label className="d-inline-block summary-value">{t(item[keys[j]].value + "")}</label>
                    </li>
                );
            }
        }
        return list;
    };

    renderSummary = () => {
        const { summary, changedIndex } = this.state;
        const retData = [];
        const length = summary.length;
        for (let i = 0; i < length; i++) {
            const item = summary[i];
            if (item.isDynamic) {
                const items = item.items;
                for (let j = 0; j < items.length; j++) {
                    const header = items[j].header;
                    const list = this.renderItem(items[j]);
                    if (list.length > 0) {
                        retData.push(
                            <div key={`row-${i}`} className="summary-box" index={retData.length} ref={changedIndex == i ? this.changedElement : null}>
                                <ul className="">
                                    {
                                        header &&
                                        <li key={`subrow-${j}`} className="d-block p-0">
                                            <label className="d-inline-block summary-label">{header}</label>
                                        </li>
                                    }
                                    {list}
                                </ul>
                            </div>
                        );
                    }
                }
            } else {
                const list = this.renderItem(item);
                if (list.length > 0) {
                    retData.push(
                        <div key={`row-${i}`} className="summary-box" index={retData.length} ref={changedIndex == i ? this.changedElement : null}>
                            <ul className="">
                                {list}
                            </ul>
                        </div>
                    );
                }
            }
        }
        return retData;
    };

    renderThumb = ({ style, ...props }) => {
        return <div style={{ ...style, backgroundColor: "#FFF", right: "2px", width: "6px", borderRadius: "3px", height: "100%" }} {...props} />;
    };

    render() {
        const { t, isLightMode } = this.props;
        return (
            <div className={`test-detail-rightbar ${isLightMode ? 'light-mode' : ''}`}>
                <div className="w-100 summary-container">
                    <div className="heading">{t("Test summary")}</div>
                    <Scrollbars ref={this.scrollRef} renderTrackVertical={this.renderThumb} style={{ height: "calc( 100% - 130px )" }}>
                        <div style={{ paddingRight: "13px" }}>
                            {this.renderSummary()}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const connectedTestSummary = withTranslation()(connect(mapStateToProps)(TestSummary));
export { connectedTestSummary as TestSummary };
