import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../_routes/loginRoutes';
import Navbar from 'react-bootstrap/Navbar'
import { Nav, NavItem } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { withTranslation } from "react-i18next";
import i18next from 'i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import logo from '../_assets/images/logo-login.svg';
import bars from '../_assets/images/bars.png';
import close from '../_assets/images/close.png';
import '../_assets/scss/login-layout.scss';
import { commonFunctions, alert, GA } from '../_utilities';
import { eventActions, eventCategories } from '../_constants';
import ResponsiveMenu from "react-responsive-navbar";

const styles = {
    button: {
        border: "1px solid #4E4EA7",
        height: "36px",
        minWidth: "129px",
        fontWeight: "bold",
        color: "#727C9B",
        padding: "5px 15px",
        borderRadius: "9px",
        fontSize: "16px",
        lineHeight: "20px",
        textTransform: "none",
        textDecoration: "none",
        textAlign: "center",
        width: "fit-content",
        background: "transparent"
    },
};

class LoginLayout extends Component {
    constructor(props) {
        super(props);
        let language = localStorage.getItem("language");
        if (!language) {
            language = "en";
            localStorage.setItem("language", "en");
        } 
        //This is patch. right now login lang is en
        language = "en";
        i18next.changeLanguage(language);
        let fLanguage = language === "en" ? "English" : "Norwegian";
        this.state = {
            language: language,
            languageFullName: fLanguage
        };
        this.props.history.listen((location, action) => {
            GA.dispatchGAEvent(eventCategories.USER, eventActions.PAGEVIEW, `page=${location.pathname}`);
        });
    }

    componentDidMount() {
        let search = this.props.location.search;
        if (search) {
            let urlParams = commonFunctions.getJsonFromUrl(search);
            if (urlParams && urlParams.lan) {
                this.onClickChangeLanguage(urlParams.lan);
            }
        }
    }

    createRoutes = () => {
        const retData = routes.map((route, index) => {
            return (route.component) ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                        <route.component {...props} />
                    )} />
            ) : (null);
        });
        return retData;
    };

    onClickRegister = () => {
        const { history } = this.props;
        history.push("/prelogin/register")
    };

    onClickLogin = () => {
        const { history } = this.props;
        history.push("/prelogin/login")
    };

    onClickChangeLanguage = (language) => {
        let fLanguage = language === "en" ? "English" : "Norwegian";
        i18next.changeLanguage(language);
        localStorage.setItem("language", language);
        this.setState({
            language,
            languageFullName: fLanguage
        });
    };

    render() {
        const { location, t } = this.props;
        const showLogin = location.pathname.indexOf("/prelogin/register") !== -1 || location.pathname.indexOf("/prelogin/verifyemail") !== -1 || location.pathname.indexOf("/prelogin/forgotpassword") !== -1;
        const showRegister = location.pathname.indexOf("/prelogin/login") !== -1;
        const { languageFullName, language } = this.state;
        return (
            <div className="login-main-container">

                <div className="d-block header-main">
                    <div className="header-container">
                        <div className="d-flex">
                            <a href={`https://tryio.no?lan=${language}`} className="logo">
                                <img src={logo} />
                                <label className="logo-text">TRYIO</label>
                            </a>
                            <div className="toggle_header">
                                <ul className="navigation-block">
                                    <li>
                                        <a
                                            href="https://tryio.no/"
                                            className="Nav_link"
                                            activeClassName="activeRoute"
                                            activeStyle={{ color: "#3c476c" }}
                                        >
                                            {" "}
                                            {t("For businesses")}{" "}
                                        </a>
                                    </li>
                                    <li className="right-border"></li>
                                    <li>
                                        <a
                                            href="https://tryio.no/tester"
                                            className="Nav_link"
                                            activeClassName="activeRoute"
                                            activeStyle={{ color: "#3c476c" }}
                                        >
                                            {" "}
                                            {t("For testers")}{" "}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ResponsiveMenu
                            menuOpenButton={
                                <div className="responsive-navbar-open">
                                    <img src={bars} alt />
                                </div>
                            }
                            menuCloseButton={
                                <div className="responsive-navbar-close">
                                    <img src={close} alt />
                                    <span>Close</span>
                                </div>
                            }
                            changeMenuOn="992px"
                            largeMenuClassName="large-menu-classname"
                            smallMenuClassName="small-menu-classname"
                            menu={
                                <ul className="navigation-block">
                                    <div style={{ display: "none" }} className="toggle_header1">
                                        <li>
                                            <a
                                                href="https://tryio.no/"
                                                className="Nav_link"
                                                activeClassName="activeRoute"
                                                activeStyle={{ color: "#3c476c" }}
                                            >
                                                {" "}
                                                {t("For Businesses")}{" "}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://tryio.no/tester"
                                                className="Nav_link"
                                                activeClassName="activeRoute"
                                                activeStyle={{ color: "#3c476c" }}
                                            >
                                                {" "}
                                                {t("For Testers")}{" "}
                                            </a>
                                        </li>
                                    </div>

                                    {/* <Dropdown className="d-inline-block language-dropdown language_toggler">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="language_toggler">
                                            {t(languageFullName)}
                                            <span className="language_toggler_arrow"><KeyboardArrowDownIcon /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="dropdown_options" onClick={e => this.onClickChangeLanguage("en")}>{t("English")}</Dropdown.Item>
                                            <Dropdown.Item className="dropdown_options" onClick={e => this.onClickChangeLanguage("no")}>{t("Norwegian")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}

                                    <div className="navbar-button-block">
                                        {/* <li>
                                            <a
                                                activeClassName="activeRoute" className="Nav_link"
                                                href="https://tryio.no/price"
                                            >
                                                {t("Pricing")}
                                            </a>
                                        </li>
                                        <li>
                                            <a activeClassName="activeRoute" className="Nav_link" href="https://tryio.no/blog" >
                                                {t("Blog")}
                                            </a>
                                        </li> */}
                                        <li>
                                            {
                                                showLogin &&
                                                <Button className="button-with-border" to="#" onMouseUp={this.onClickLogin}>
                                                    {t("Sign in")}
                                                </Button>
                                            }
                                            {
                                                showRegister &&
                                                <Button className="button-with-border" to="#" onMouseUp={this.onClickRegister}>
                                                    {t("Sign up")}
                                                </Button>
                                            }
                                        </li>
                                        <li className="book-btn">
                                            <Button className="button-with-border book" onClick={() => window.location.href = "https://tryio.no/demo"} >
                                                {t("Book a Demo")}
                                            </Button>
                                        </li>
                                    </div>
                                </ul>
                            }
                        />
                    </div>
                </div>

                <div className="login-component-container">
                    {this.createRoutes()}
                </div>
                <div className="footer-container">
                    <div className="footer-wrapper">
                        <ul className="link-block">
                            <li>
                                <a href={`https://tryio.no?lan=${language}`}>
                                    {t("Home")}
                                </a>
                            </li>
                            <li>
                                <a href={`mailto:mzia@tryio.no`}>
                                    {t("Contact us")}
                                </a>
                            </li>
                            <li>
                                <a href="https://appbackend.tryio.no/privacy-policies" target="_banck">
                                    {t("Privacy policy")}
                                </a>
                            </li>
                        </ul>
                        <div className="created-at-block">
                            <p>&copy; Tryio AS 2020</p>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const connectedLoginLayout = withTranslation()(connect(mapStateToProps)(LoginLayout));
export { connectedLoginLayout as LoginLayout };
