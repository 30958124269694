export const testConstants = {

    SET_NEW_CREATED_TEST_STATUS: 'SET_NEW_CREATED_TEST_STATUS',

    CREATE_TEST_REQUEST: 'CREATE_TEST_REQUEST',
    CREATE_TEST_SUCCESS: 'CREATE_TEST_SUCCESS',
    CREATE_TEST_FAILURE: 'CREATE_TEST_FAILURE',

    GET_ALL_TEST_REQUEST: 'GET_ALL_TEST_REQUEST',
    GET_ALL_TEST_SUCCESS: 'GET_ALL_TEST_SUCCESS',
    GET_ALL_TEST_FAILURE: 'GET_ALL_TEST_FAILURE',

    GET_ALL_QUESTIONNAIRE_REQUEST: 'GET_ALL_QUESTIONNAIRE_REQUEST',
    GET_ALL_QUESTIONNAIRE_SUCCESS: 'GET_ALL_QUESTIONNAIRE_SUCCESS',
    GET_ALL_QUESTIONNAIRE_FAILURE: 'GET_ALL_QUESTIONNAIRE_FAILURE',

    GET_INDIVIDUAL_TEST_REQUEST: 'GET_INDIVIDUAL_TEST_REQUEST',
    GET_INDIVIDUAL_TEST_SUCCESS: 'GET_INDIVIDUAL_TEST_SUCCESS',
    GET_INDIVIDUAL_TEST_FAILURE: 'GET_INDIVIDUAL_TEST_FAILURE',

    GET_DIETARY_TAGS_REQUEST: 'GET_DIETARY_TAGS_REQUEST',
    GET_DIETARY_TAGS_SUCCESS: 'GET_DIETARY_TAGS_SUCCESS',
    GET_DIETARY_TAGS_FAILURE: 'GET_DIETARY_TAGS_FAILURE',

    GET_ALERGENS_LIST_REQUEST: 'GET_ALERGENS_LIST_REQUEST',
    GET_ALERGENS_LIST_SUCCESS: 'GET_ALERGENS_LIST_SUCCESS',
    GET_ALERGENS_LIST_FAILURE: 'GET_ALERGENS_LIST_FAILURE',

    GET_FAMILY_SITUATION_REQUEST: 'GET_FAMILY_SITUATION_REQUEST',
    GET_FAMILY_SITUATION_SUCCESS: 'GET_FAMILY_SITUATION_SUCCESS',
    GET_FAMILY_SITUATION_FAILURE: 'GET_FAMILY_SITUATION_FAILURE',

    GET_EDUCATION_LEVEL_REQUEST: 'GET_EDUCATION_LEVEL_REQUEST',
    GET_EDUCATION_LEVEL_SUCCESS: 'GET_EDUCATION_LEVEL_SUCCESS',
    GET_EDUCATION_LEVEL_FAILURE: 'GET_EDUCATION_LEVEL_FAILURE',

    GET_INCOME_LEVEL_REQUEST: 'GET_INCOME_LEVEL_REQUEST',
    GET_INCOME_LEVEL_SUCCESS: 'GET_INCOME_LEVEL_SUCCESS',
    GET_INCOME_LEVEL_FAILURE: 'GET_INCOME_LEVEL_FAILURE',

    GET_LOCATION_REQUEST: 'GET_LOCATION_REQUEST',
    GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
    GET_LOCATION_FAILURE: 'GET_LOCATION_FAILURE',

    DELETE_TEST_REQUEST: 'DELETE_TEST_REQUEST',
    DELETE_TEST_SUCCESS: 'DELETE_TEST_SUCCESS',
    DELETE_TEST_FAILURE: 'DELETE_TEST_FAILURE',

    GET_TESTS_BY_DATE_REQUEST: 'GET_TESTS_BY_DATE_REQUEST',
    GET_TESTS_BY_DATE_SUCCESS: 'GET_TESTS_BY_DATE_SUCCESS',
    GET_TESTS_BY_DATE_FAILURE: 'GET_TESTS_BY_DATE_FAILURE',

    GET_TEST_GALLERY_REQUEST: 'GET_TEST_GALLERY_REQUEST',
    GET_TEST_GALLERY_SUCCESS: 'GET_TEST_GALLERY_SUCCESS',
    GET_TEST_GALLERY_FAILURE: 'GET_TEST_GALLERY_FAILURE',

    UPLOAD_GALLERY_IMAGE_REQUEST: 'UPLOAD_GALLERY_IMAGE_REQUEST',
    UPLOAD_GALLERY_IMAGE_SUCCESS: 'UPLOAD_GALLERY_IMAGE_SUCCESS',
    UPLOAD_GALLERY_IMAGE_FAILURE: 'UPLOAD_GALLERY_IMAGE_FAILURE',

    EDIT_TEST_IMAGE_REQUEST: 'EDIT_TEST_IMAGE_REQUEST',
    EDIT_TEST_IMAGE_SUCCESS: 'EDIT_TEST_IMAGE_SUCCESS',
    EDIT_TEST_IMAGE_FAILURE: 'EDIT_TEST_IMAGE_FAILURE',

    DOWNLOAD_PARTICIPANT_LIST_REQUEST: 'DOWNLOAD_PARTICIPANT_LIST_REQUEST',
    DOWNLOAD_PARTICIPANT_LIST_SUCCESS: 'DOWNLOAD_PARTICIPANT_LIST_SUCCESS',
    DOWNLOAD_PARTICIPANT_LIST_FAILURE: 'DOWNLOAD_PARTICIPANT_LIST_FAILURE',

    TESTS_WITHOUT_QUESTIONNAIRE_REQUEST: 'TESTS_WITHOUT_QUESTIONNAIRE_REQUEST',
    TESTS_WITHOUT_QUESTIONNAIRE_SUCCESS: 'TESTS_WITHOUT_QUESTIONNAIRE_SUCCESS',
    TESTS_WITHOUT_QUESTIONNAIRE_FAILURE: 'TESTS_WITHOUT_QUESTIONNAIRE_FAILURE',
};