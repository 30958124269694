import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

class HedonicScale extends Component {
    ref = null;
    constructor(props) {
        super(props);
        this.state = {}
        this.ref = React.createRef();
    }

    renderSummaryHeading() {
        const { t, question, index, noOfParticipants, totalConsumers } = this.props;
        return (
            <div className="d-block heading">
                <div className="d-inline-block align-top heading-number" style={{ backgroundColor: "#8DB1C3" }}>{index}</div>
                <div className="d-inline-block align-top heading-text">
                    <label>{question}</label>
                    <p>{totalConsumers} {t('out of')} {noOfParticipants} {t("answered this question")}</p>
                </div>
            </div>
        );
    }

    renderSummaryLines() {
        const { summ, index, totalConsumers, showPercentage } = this.props;
        const retData = [];
        if (summ && summ.length > 0) {
            for (let i = 0; i < summ.length; i++) {
                const data = summ[i];
                const percentage = parseFloat((data.cnt / totalConsumers) * 100).toFixed(1);
                retData.push(
                    <div key={`${i}-hedonicScale-${index}`} className="col" style={{ width: '11.11111111111%', paddingRight: '7px', paddingLeft: '7px' }}>
                        <div className="d-flex align-content-end flex-wrap graph-lines">
                            <div className="d-flex align-content-end flex-wrap graph" style={{ width: '100%' }}>
                                <div className="d-flex align-content-end flex-wrap graph-inner">
                                    {showPercentage && <label>{parseInt(percentage) !== 0 ? `${percentage}%` : ""}</label>}
                                    <span style={{ backgroundColor: "rgba(140, 176, 193, 1)", height: percentage + '%' }}>{percentage}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        return retData;
    };

    renderSummaryTexts() {
        const { opts } = this.props;
        const retData = [];
        for (let i = 0; i < opts.length; i++) {
            const data = opts[i];
            retData.push(
                <div key={`${i}-hedonicScale-${i}`} className="col" style={{ width: '11.11111111111%', paddingRight: '0px', paddingLeft: '0px' }}>
                    <p>{i + 1}</p>
                    <p>{data}</p>
                </div>
            );
        }
        return retData;
    };

    getChartHeight = () => {
        const hedonicHeight = this.ref.current.clientHeight;
        return hedonicHeight;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="question-container" ref={this.ref}>
                {this.renderSummaryHeading()}
                <div className="d-block question-inner">
                    <div className="d-block grid-graph-container">
                        <div className="d-block grid-graph-scrollbar line-scale-graph-scrollbar" style={{ height: '350px', paddingLeft: '0px' }}>
                            <div className="d-flex align-content-end flex-wrap grid-graph" style={{ backgroundColor: 'rgba(225, 233, 240, 1)', width: '100%' }}>
                                <div class="row" style={{ width: '100%', marginRight: '0px', marginLeft: '0px' }}>
                                    {this.renderSummaryLines()}
                                </div>
                            </div>
                            <div className="d-block line-scale-graph-text hedonic-graph-text" style={{ width: '100%' }}>
                                <div className="row" style={{ width: '100%', marginLeft: '0px', marginRight: '0px' }}>
                                    {this.renderSummaryTexts()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const translatedHeadonicScale = withTranslation('', { withRef: true })(HedonicScale);
export { translatedHeadonicScale as HedonicScale };