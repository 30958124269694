import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import './../../_assets/scss/summary.scss';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import { InsightsBar } from './InsightsBar';
import { Scrollbars } from 'react-custom-scrollbars';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { MultipleChoice, SingleChoice, PrintTextSummary, LineScale, HedonicScale, Rating, Grid, Jar, MultipleAllSamples, SingleAllSamples, HedonicScaleAllSamples, LineScaleAllSamples, JarAllSamples } from './_components';
import { questionnaireAction } from '../../_actions';
import { status, componentType } from '../../_constants';
import { ButtonLoader } from '../../_components';
import Dialog from '@material-ui/core/Dialog';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const NewRadio = withStyles({
    root: {
        color: "#009688",
        '&$checked': {
            color: "#009688",
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const styles = {
    blueButton: {
        color: "#FFFFFF",
        backgroundColor: "#5C5E9D",
        borderRadius: "2px",
        textAlign: "center",
        textTransform: "uppercase",
        textAlign: "center",
    },
    feedbackButton: {
        fontWeight: "400",
        fontSize: "12px",
        fontFamily: "'Open Sans',sans-serif",
        width: "100%",
        height: "28px",
        textTransform: "none",
    },
    cancelButton: {
        width: "105px",
        height: "37px",
        color: "#8c8c90",
        fontSize: "15px",
        background: "#e2e2e9",
        borderRadius: "0px",
        textTransform: "none"
    },
    downloadTest: {
        width: "105px",
        height: "37px",
        color: "#F6F7FB",
        fontSize: "15px",
        background: "#6264A8",
        borderRadius: "0px",
        textTransform: "none"
    }
}

class Summary extends Component {
    WIDTH = 90;
    PRINT_PAGE_HEIGHT = 979;
    ref = null;
    constructor(props) {
        super(props);
        this.state = {
            testId: "",
            feedbacks: [],
            testDetails: {},
            activeTesterIndex: 0,
            start: {},
            sample: {},
            end: {},
            activeTab: 0,
            totalResponseCount: 0,
            activeSession: -1,
            isOpenDownloadDialog: false,
            setdownloadtype: 'csv',
        }
        this.scrollRef = React.createRef();
        this.ref = React.createRef();
        this.counter = 0;
    }

    componentDidMount() {
        const { match } = this.props;
        if (match && match.params && match.params.testId && match.params.token && match.params.isAdmin) {
            this.setState({
                testId: match.params.testId,
                token: match.params.token
            });
            this.props.dispatch(questionnaireAction.getTestSummaryForPrint({ testId: match.params.testId, token: match.params.token, isAdmin: match.params.isAdmin }));
        } else {
            this.props.history.push('/postlogin/questionnairelist');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.get_test_summary_print_status !== this.props.get_test_summary_print_status && this.props.get_test_summary_print_status === status.SUCCESS) {
            const { testSummaryDataPrint } = this.props;
            if (testSummaryDataPrint && testSummaryDataPrint.summary) {
                this.setState({
                    start: testSummaryDataPrint.summary.start,
                    sample: testSummaryDataPrint.summary.sample,
                    end: testSummaryDataPrint.summary.end,
                    totalResponseCount: testSummaryDataPrint.summary.totalResponseCount
                }, () => {
                    this.getChartHeight();
                });
            }
            if (testSummaryDataPrint && testSummaryDataPrint.test) {
                this.setState({
                    testDetails: testSummaryDataPrint.test,
                });
            }
        }
        if (prevProps.get_test_summary_session_status !== this.props.get_test_summary_session_status && this.props.get_test_summary_session_status === status.SUCCESS) {
            const { testSummaryDataForSession } = this.props;
            if (testSummaryDataForSession && testSummaryDataForSession.summary) {
                this.setState({
                    start: testSummaryDataForSession.summary.start,
                    sample: testSummaryDataForSession.summary.sample,
                    end: testSummaryDataForSession.summary.end,
                    totalResponseCount: testSummaryDataForSession.summary.totalResponseCount
                });
            }
        }
        if (prevProps.get_dowload_file_status !== this.props.get_dowload_file_status && this.props.get_dowload_file_status === status.SUCCESS) {
            const { downloadFileUrl } = this.props;
            this.downloadFileUrl(downloadFileUrl, 'answers.' + this.state.setdownloadtype);
        }

    }

    getChartHeight = () => {
        const questionContainers = document.querySelectorAll(".question-container");
        for (let i = 0; i < questionContainers.length; i++) {
            const container = questionContainers[i];
            let height = container.clientHeight;
            let padding = 0;
            if (height < this.PRINT_PAGE_HEIGHT) {
                padding = (this.PRINT_PAGE_HEIGHT - height) / 2;
            } else {
                const factor = Math.ceil(height / this.PRINT_PAGE_HEIGHT);
                padding = (this.PRINT_PAGE_HEIGHT * factor - height) / 2;
            }
            container.parentElement.style.paddingTop = `${padding}px`;
            container.parentElement.style.paddingBottom = `${padding}px`;
        }
    };

    changeScroll = (factor) => {
        const scrollLeft = this.scrollRef.current.getScrollLeft();
        this.scrollRef.current.view.scroll({ behavior: 'smooth', left: scrollLeft + factor * this.WIDTH });
    };

    renderSummary = () => {
        const { start, end, sample, activeTab, startHeights, endHeights } = this.state;
        const retData = [];
        this.counter = 0;
        if (start && start.length > 0 && (activeTab == 1 || activeTab == 0)) {
            const startComponents = this.renderPartSummary(start);
            retData.push(startComponents);
        }
        if (sample && sample.length > 0 && (activeTab == 2 || activeTab == 0)) {
            const sampleComponents = this.renderSampleSummary();
            retData.push(sampleComponents);
        }
        if (end && end.length > 0 && (activeTab == 3 || activeTab == 0)) {
            const endComponents = this.renderPartSummary(end);
            retData.push(endComponents);
        }
        return retData;
    };

    renderPartSummary = (summaryData) => {
        const retData = [];
        const { testDetails, totalResponseCount } = this.state;
        if (summaryData) {
            for (let i = 0; i < summaryData.length; i++) {
                const ref = React.createRef();
                const summary = summaryData[i];
                if (summary.type === componentType.DESCRIPTION) {
                    // retData.push(<Description index={i + 1} {...question} />);
                } else if (summary.type === componentType.MULTIPLE_CHOICE) {
                    retData.push(<div style={{borderBottom: "1px solid #ababab"}} key={summary._id}><MultipleChoice ref={ref} showPercentage={true} index={this.counter + 1} {...summary} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
                    this.counter += 1;
                } else if (summary.type === componentType.SINGLE_CHOICE) {
                    retData.push(<div style={{borderBottom: "1px solid #ababab"}} key={summary._id}><SingleChoice ref={ref} showPercentage={true} index={this.counter + 1} {...summary} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
                    this.counter += 1;
                } else if (summary.type === componentType.RATE) {
                    retData.push(<div style={{borderBottom: "1px solid #ababab"}} key={summary._id}><Rating ref={ref} showPercentage={true} index={this.counter + 1} {...summary} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
                    this.counter += 1;
                } else if (summary.type === componentType.LINESCALE) {
                    retData.push(<div style={{borderBottom: "1px solid #ababab"}} key={summary._id}><LineScale ref={ref} showPercentage={true} index={this.counter + 1} {...summary} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
                    this.counter += 1;
                } else if (summary.type === componentType.HEDONICSCALE) {
                    retData.push(<div style={{borderBottom: "1px solid #ababab"}} key={summary._id}><HedonicScale ref={ref} showPercentage={true} index={this.counter + 1} {...summary} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
                    this.counter += 1;
                } else if (summary.type === componentType.JAR) {
                    retData.push(<div style={{borderBottom: "1px solid #ababab"}} key={summary._id}><Jar ref={ref} showPercentage={true} index={this.counter + 1} {...summary} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
                    this.counter += 1;
                } else if (summary.type === componentType.FREE_TEXT) {
                    retData.push(<div style={{borderBottom: "1px solid #ababab"}} key={summary._id}><PrintTextSummary ref={ref} index={this.counter + 1} {...summary} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
                    this.counter += 1;
                } else if (summary.type === componentType.GRID) {
                    retData.push(<div style={{borderBottom: "1px solid #ababab"}} key={summary._id}><Grid ref={ref} showPercentage={true} index={this.counter + 1} {...summary} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
                    this.counter += 1;
                }
            }
        }
        return retData;
    };

    renderSamplePartSummary = (type, data) => {
        const { testDetails, totalResponseCount } = this.state;
        const retData = [];
        if (type === componentType.SINGLE_CHOICE) {
            retData.push(<div style={{borderBottom: "1px solid #ababab"}} ><SingleAllSamples showPercentage={true} index={this.counter + 1} {...data} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
            this.counter += 1;
        } else if (type === componentType.MULTIPLE_CHOICE) {
            retData.push(<div style={{borderBottom: "1px solid #ababab"}} ><MultipleAllSamples showPercentage={true} index={this.counter + 1} {...data} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
            this.counter += 1;
        } else if (type === componentType.HEDONICSCALE) {
            retData.push(<div style={{borderBottom: "1px solid #ababab"}} ><HedonicScaleAllSamples showPercentage={true} index={this.counter + 1} {...data} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
            this.counter += 1;
        } else if (type === componentType.LINESCALE) {
            retData.push(<div style={{borderBottom: "1px solid #ababab"}} ><LineScaleAllSamples showPercentage={true} index={this.counter + 1} {...data} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
            this.counter += 1;
        } else if (type === componentType.JAR) {
            retData.push(<div style={{borderBottom: "1px solid #ababab"}} ><JarAllSamples showPercentage={true} index={this.counter + 1} {...data} totalConsumers={totalResponseCount} noOfParticipants={testDetails.noOfParticipants} /></div>);
            this.counter += 1;
        }
        return retData;
    };

    renderSampleSummary = () => {
        const { sample, sampleHeights } = this.state;
        let sampleSummary = {};
        for (let i = 0; i < sample.length; i++) {
            let questions = sample[i].question;
            let sampleCode = sample[i].sample;
            for (let j = 0; j < questions.length; j++) {
                let question = questions[j];
                sampleSummary[j] = sampleSummary[j] || { summary: [], type: question.type, question: question.question };
                sampleSummary[j].summary.push({ question, sample: sampleCode });
            }
        }
        const retData = [];
        const keys = Object.keys(sampleSummary);

        for (let i = 0; i < keys.length; i++) {
            let partSummary = sampleSummary[keys[i]];
            const partSummaryComponent = this.renderSamplePartSummary(partSummary.type, partSummary);
            retData.push(partSummaryComponent);
        }
        return retData;
    };

    onClickTab = (activeTab) => {
        this.setState({
            activeTab
        });
    }

    onClickSession = (id, index) => {
        const { testDetails } = this.state;
        if (id) {
            this.props.dispatch(questionnaireAction.getSummaryForSession({
                testId: testDetails._id,
                sessionId: id
            }));
        } else {
            index = -1;
            this.props.dispatch(questionnaireAction.getTestSummary({ testId: testDetails._id }));
        }
        this.setState({
            activeSession: index
        });
    };

    renderSessionTabs = (testDetails) => {
        const retData = [];
        const { t } = this.props;
        const { activeSession } = this.state;
        if (testDetails && testDetails.type === "location") {
            const sessions = testDetails.sessions;
            for (let i = 0; i < sessions.length; i++) {
                retData.push(
                    <li className={`${i === activeSession ? 'active' : ''}`} onClick={() => { this.onClickSession(sessions[i]._id, i) }} key={`session-tab-${i}`}><a href="javascript:void(0)">{t("SESSION")} {i + 1}</a></li>
                );
            }
        }
        return retData;
    };

    calculateWidth = (testDetails) => {
        if (testDetails && testDetails.type === "location") {
            return this.WIDTH + (80 * testDetails.sessions.length);
        }
        return 0;
    };

    showDownloadDialog = (show) => {
        this.setState({
            isOpenDownloadDialog: show
        });
    };

    downloadFile = () => {
        const { setdownloadtype, testDetails } = this.state;
        this.props.dispatch(questionnaireAction.getDownloadFile({ testId: testDetails._id, type: setdownloadtype }));
    }

    downloadFileUrl(data, fileName) {
        data.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = fileName;
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        });
    }

    setDownloadType = (e) => {
        this.setState({
            setdownloadtype: e.target.value,
        })
    }

    render() {
        const { testDetails, activeTab, testId, activeSession, isOpenDownloadDialog, setdownloadtype } = this.state;
        const { t, get_dowload_file_status } = this.props;
        const width = this.calculateWidth(testDetails);
        return (
            <div className="content-inner-container summary-container print-summary-container">
                <div className="main-outer">
                    <div className="main-inner">
                        <div className={`d-block summarys-questions-container ${testDetails && testDetails.type === "home" ? 'hometest' : ''}`} style={{ marginTop: 0, padding: 0 }}>
                            {this.renderSummary()}
                        </div>
                    </div>
                </div>
                {/* <InsightsBar testDetails={testDetails} /> */}

                {/* <Dialog open={isOpenDownloadDialog} maxWidth="md" className="download-summary-dialog">
                    <div className="close-dialog-btn" onClick={() => this.showDownloadDialog(false)}>
                        <ClearIcon />
                    </div>
                    <div className="dialog-header">
                        {t("Choose the file type to download.")}
                    </div>
                    <div className="header-border"></div>
                    <div className="radio-button-main-container">
                        <div className="radio-button-container" style={{ marginBottom: "29px" }}>
                            <div>
                                <NewRadio id="csv-summary" type="radio" checked={setdownloadtype === "csv"} value="csv" name="dwltype" onChange={this.setDownloadType} />
                            </div>
                            <div>
                                <div className="button-label" htmlFor="csv-summary">{t("CSV file")}</div>
                                <div className="button-description">{t("Works with Google Sheets and other tools")}</div>
                            </div>
                        </div>
                        <div className="radio-button-container">
                            <div>
                                <NewRadio id="xl-summary" checked={setdownloadtype === "xls"} type="radio" value="xls" name="dwltype" onChange={this.setDownloadType} />
                            </div>
                            <div>
                                <div className="button-label" htmlFor="xl-summary">{t("XLSX file")}</div>
                                <div className="button-description">{t("Works with Excel 2007 and newer")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="button-container" style={{ textAlign: "right" }}>
                        <Button onClick={() => this.showDownloadDialog(false)} className="mr-2" style={styles.cancelButton}>{t("Cancel")}</Button>
                        <Button onClick={this.downloadFile} style={styles.downloadTest} disabled={get_dowload_file_status === status.IN_PROGRESS}>
                            {
                                get_dowload_file_status !== status.IN_PROGRESS &&
                                t("Download")
                            }
                            {
                                get_dowload_file_status === status.IN_PROGRESS &&
                                <ButtonLoader />
                            }
                        </Button>
                    </div>
                </Dialog> */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { get_test_summary_print_status, testSummaryDataPrint, get_test_summary_session_status, testSummaryDataForSession, get_dowload_file_status, downloadFileUrl } = state.questionnaire;
    return {
        get_test_summary_print_status,
        testSummaryDataPrint,
        get_test_summary_session_status,
        testSummaryDataForSession,
        get_dowload_file_status,
        downloadFileUrl
    };
}

const connectedSummary = withTranslation()(connect(mapStateToProps)(Summary));
export default connectedSummary;
