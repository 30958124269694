import { combineReducers } from 'redux';

import { auth } from './auth.reducers';
import { test } from './test.reducers';
import { user } from './user.reducers';
import { authConstants } from "../_constants";
import { questionnaire } from './questionnaire.reducers';

const appReducers = combineReducers({
    auth,
    test,
    user,
    questionnaire
});

const rootReducer = (state, action) => {
    if (action.type === authConstants.LOGOUT) {
        state = undefined;
    }
    return appReducers(state, action);
}

export default rootReducer;
