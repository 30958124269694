import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { CustomTooltip } from "../../_components";

class JarAllSamples extends Component {
    ref = null;
    colors = ["#E2BDA2", "#BF9677", "#936847", "#A05B26", "#773400"];
    constructor(props) {
        super(props);
        this.state = {
        }
        this.ref = React.createRef();
    }

    renderSummaryHeading() {
        const { t, question, index, noOfParticipants, totalConsumers } = this.props;
        return (
            <div className="d-block heading">
                <div className="d-inline-block align-top heading-number" style={{ backgroundColor: "#BF9677" }}>{index}</div>
                <div className="d-inline-block align-top heading-text">
                    <label>{question}</label>
                    <p>{totalConsumers} {t('out of')} {noOfParticipants} {t("answered this question")}</p>
                </div>
            </div>
        );
    }

    renderSummaryGridSamples() {
        const { summary, index } = this.props;
        const retData = [];
        for (let i = 0; i < summary.length; i++) {
            const data = summary[i];
            retData.push(
                <div key={`${i}-jar-scale-sample-name-${index}`} className="d-inline-block sample-text">
                    <span className="d-inline-block" style={{ backgroundColor: this.colors[i] }}></span>
                    <label>{data.sample}</label>
                </div>
            );
        }
        return retData;
    }

    renderSummaryLines() {
        const retData = [];
        const { summary, index, totalConsumers, showPercentage } = this.props;
        let summ = [];
        if (summary && summary.length > 0) {
            summ = summary[0].question.summ;
        }
        let showTooltip = summary.length > 3;
        for (let i = 0; i < summ.length; i++) {
            let graphLines = [];
            for (let j = 0; j < summary.length; j++) {
                const ans = summary[j].question.summ[i];
                const percentage = parseFloat((ans.cnt / totalConsumers) * 100).toFixed(1);
                graphLines.push(
                    <div key={`jar-scale-sample-graph-line-${j}-${index}`} className="d-flex align-content-end graph">
                        <div className="d-flex align-content-end flex-wrap graph-inner">
                            {(showPercentage || !showTooltip) && <label>{parseInt(percentage) !== 0 ? `${percentage}%` : ""}</label>}
                            {
                                showTooltip &&
                                <CustomTooltip placement="top" title={parseInt(percentage) !== 0 ? `${percentage}%` : ""}>
                                    <span style={{ backgroundColor: this.colors[j], height: percentage + '%' }}>{percentage}%</span>
                                </CustomTooltip>
                            }
                            {
                                !showTooltip &&
                                <span style={{ backgroundColor: this.colors[j], height: percentage + '%' }}>{percentage}%</span>
                            }
                        </div>
                    </div>
                );
            }
            retData.push(
                <div key={`jar-scale-sample-graph-${i}-${index}`} className="col" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <div className="d-flex align-content-end justify-content-center graph-lines">
                        {graphLines}
                    </div>
                </div>
            )
        }
        return retData;
    };

    renderSummaryTexts() {
        const { summary, index } = this.props;
        let opts = [];
        if (summary && summary.length > 0) {
            opts = summary[0].question.opts;
        }
        const retData = [];
        let counter = -2;
        for (let i = 0; i < 5; i++) {
            const data = opts[i];
            retData.push(
                <div key={`jar-scale-scale-sample-graph-text-${i}-${index}`} className="col" style={{ paddingLeft: '7px', paddingRight: '7px' }}>
                    <p>{counter}</p>
                    <p>{data}</p>
                </div>
            );
            counter += 1;
        }
        return retData;
    };

    getChartHeight = () => {
        const jarAllHeight = this.ref.current.clientHeight;
        return jarAllHeight;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="question-container" ref={this.ref}>
                {this.renderSummaryHeading()}
                <div className="d-block question-inner">
                    <div className="d-block grid-graph-container">
                        <div className="d-block sample-text-blocks">
                            {this.renderSummaryGridSamples()}
                        </div>
                        <div className="d-block grid-graph-scrollbar line-scale-graph-scrollbar" style={{ height: '275px', paddingLeft: '0px', marginLeft: '0' }}>
                            <div className="d-flex align-content-end flex-wrap grid-graph" style={{ backgroundColor: 'rgba(191, 150, 119, 0.20)', width: '100%' }}>
                                <div class="row flex-nowrap" style={{ width: '100%', marginBottom: '0px', marginRight: '0px', marginLeft: '0px' }}>
                                    {this.renderSummaryLines()}
                                </div>
                            </div>
                            <div className="d-block grid-graph-text" style={{ width: '100%' }}>
                                <div className="row flex-nowrap" style={{ width: '100%', marginLeft: '0px', marginRight: '0px' }}>
                                    {this.renderSummaryTexts()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const translatedJarAllSamples = withTranslation('', { withRef: true })(JarAllSamples);
export { translatedJarAllSamples as JarAllSamples };