import config from '../config';
import { commonFunctions } from "../_utilities";

export const questionnaireServices = {
    addQuestionnaire,
    getIndividualQuestionnaire,
    getResponseOfQuestionnaire,
    getTestSummary,
    getSummaryForSession,
    getDownloadFile,
    getTestSummaryForPrint,
    getSummaryPDF
}

function addQuestionnaire(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const isEdit = data.isEdit;
    delete data.isEdit;
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data));
    if (isEdit) {
        return fetch(`${config.apiUrl}/api/questionnaires/edit/${data.testId}`, requestOptions).then(response => response.json());
    } else {
        return fetch(`${config.apiUrl}/api/questionnaires`, requestOptions).then(response => response.json());
    }
}

function getIndividualQuestionnaire(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/questionnaires/individual/${data.testId}`, requestOptions).then(response => response.json());
}

function getResponseOfQuestionnaire(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    let sessionUrl = '';
    if (data.sessionId) {
        sessionUrl = `?sessionId=${data.sessionId}`;
    }
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/questionnaires/feedbacks/${data.testId}${sessionUrl}`, requestOptions).then(response => response.json());
}

function getTestSummary(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/summary/individual/${data.testId}`, requestOptions).then(response => response.json());
}

function getTestSummaryForPrint(data) {
    const extraHeaders = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.token}`
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null, true);
    if(data.isAdmin === "2"){
        return fetch(`${config.apiUrl}/api/summary/individual/${data.testId}`, requestOptions).then(response => response.json());
    } else {
        return fetch(`${config.adminUrl}/summary/individual/${data.testId}`, requestOptions).then(response => response.json());
    }
}

function getSummaryForSession(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/summary/session/${data.testId}?sessionId=${data.sessionId}`, requestOptions).then(response => response.json());
}

function getDownloadFile(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data));
    return fetch(`${config.apiUrl}/api/feedbacks/generate-report/${data.testId}`, requestOptions);
}

function getSummaryPDF(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data));
    return fetch(`${config.printSummaryURL}/api/summary/printsummary`, requestOptions);
}