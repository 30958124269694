import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { I18nextProvider } from "react-i18next";
import { Loading } from '../_components';
import { GA } from '../_utilities';
import { LoginLayout, DefaultLayout } from '../Layout';
import i18n from '../_i18';
import "../_assets/scss/common.scss";
import PrintSummary from '../PostLogin/Summary/PrintSummary';

class App extends Component {
    constructor(props) {
        super(props);
        GA.initialize();
    }
    render() {
        return (
            <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                    <Suspense fallback={Loading}>
                        <Route
                            exact
                            path="/"
                            render={() => {
                                return (
                                    <Redirect to="/postlogin/dashboard" />
                                )
                            }}
                        />
                        <Route path="/printsummary/:testId/:token/:isAdmin" component={PrintSummary} />
                        <Route path="/prelogin" component={LoginLayout} />
                        <Route path="/postlogin" component={DefaultLayout} />
                    </Suspense>
                    <ToastContainer enableMultiContainer containerId={'TOP_RIGHT'} position={toast.POSITION.TOP_RIGHT} />
                </BrowserRouter>
            </I18nextProvider>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp;
