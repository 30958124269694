import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from "react-i18next";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import '../../_assets/scss/calendar-view.scss';
import { testActions } from '../../_actions';
import { commonFunctions } from '../../_utilities';
import { status } from '../../_constants';

class CalendarView extends Component {
    days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    months = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
    constructor(props) {
        super(props);
        this.state = {
            activeDate: new Date(),
            week: this.getWeek(new Date()),
            currentWeek: 0,
            currentMonth: this.months[new Date().getMonth()],
            currentYear: new Date().getFullYear(),
            startDate: "",
            endDate: "",
            testData: {},
            testsOfTheWeek: {},
            bShowTestsOfWeek: true
        };
    }

    componentDidMount() {
        const date = commonFunctions.convertDateToString(new Date());
        this.props.dispatch(testActions.getTestsByDate({ date }))
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.get_test_by_date_status !== this.props.get_test_by_date_status && this.props.get_test_by_date_status === status.SUCCESS) {
            const { testsByDateData } = this.props;
            const testData = this.manipulteTestData(testsByDateData.tests);
            const testsOfTheWeek = this.getTestsOfWeek(this.state.week, testData);
            this.setState({
                startDate: new Date(testsByDateData.startDate),
                endDate: new Date(testsByDateData.endDate),
                testData,
                testsOfTheWeek
            });
        }
    }

    getTestsOfWeek = (week, testData) => {
        const tests = {};
        for (let i = 0; i < week.length; i++) {
            let dateStr = commonFunctions.convertDateToString(week[i].date);
            if (testData[dateStr]) {
                tests[dateStr] = testData[dateStr];
            } else {
                tests[dateStr] = [];
            }
        }
        return tests;
    };

    onClickTest = (testId) => {
        this.props.history.push(`/postlogin/viewtest/${testId}`);
    };

    manipulteTestData = (testList) => {
        const retData = {};
        if (testList && testList.length > 0) {
            const length = testList.length;
            for (let i = 0; i < length; i++) {
                const test = testList[i];
                if (test.type === "location") {
                    let sessions = test.sessions;
                    for (let j = 0; j < sessions.length; j++) {
                        let date = new Date(sessions[j].date);
                        date = commonFunctions.convertDateToString(date);
                        retData[date] = retData[date] || [];
                        retData[date].push({
                            ...test,
                            startTime: sessions[j].startTime,
                            endTime: sessions[j].endTime,
                        });
                    }
                } else {
                    if (test.pickupType === "pickup") {
                        const { pickupSlots } = test;
                        for (let j = 0; j < pickupSlots.length; j++) {
                            let date = new Date(pickupSlots[j].date);
                            date = commonFunctions.convertDateToString(date);
                            retData[date] = retData[date] || [];
                            retData[date].push({
                                ...test,
                                startTime: pickupSlots[j].from,
                                endTime: pickupSlots[j].to,
                            });
                        }
                    } else {
                        let date = new Date(test.sampleSendingDate);
                        date = commonFunctions.convertDateToString(date);
                        retData[date] = retData[date] || [];
                        retData[date].push({ ...test });
                    }
                }
            }
        }
        return retData;
    };

    getWeek = (date) => {
        const week = [];
        for (let i = 1; i <= 7; i++) {
            let first = date.getDate() - date.getDay() + i
            let day = new Date(date.setDate(first));
            week.push({
                date: day,
                day: this.days[i - 1]
            })
        }
        return week;
    };

    displayWeek = () => {
        const { week, activeDate, testsOfTheWeek, bShowTestsOfWeek } = this.state;
        // const { calenderData, toDayDate } = this.state;
        const retData = [];
        for (let i = 0; i < week.length; i++) {
            const oDate = week[i].date;
            const oDateStr = commonFunctions.convertDateToString(oDate);
            let date = oDate.getDate();
            // let isActive = oDate.getDate() === activeDate.getDate() && oDate.getMonth() === activeDate.getMonth();
            let isActive = testsOfTheWeek[oDateStr] && testsOfTheWeek[oDateStr].length > 0;
            if (!bShowTestsOfWeek) {
                isActive = oDate.getDate() === activeDate.getDate() && oDate.getMonth() === activeDate.getMonth();
            }
            retData.push(
                <div key={`date-${i}`} className="d-inline-block align-top text-center day-box" onClick={e => { this.setActiveDate(oDate) }}>
                    <a className={isActive ? "d-block today" : "d-block"}>
                        <span className="d-block date">{date}</span>
                        <span className="d-block day">{week[i].day}</span>
                    </a>
                </div>
            )
        }
        return retData;
    };

    setActiveDate = (activeDate) => {
        this.setState({
            bShowTestsOfWeek: false,
            activeDate
        });
    };

    onClickViewAll = () => {
        this.setState({
            bShowTestsOfWeek: true
        });
    };

    getDateBeforeAfter = (days) => {
        let date = new Date();
        return new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
    };

    changeCurrentWeek = (e, factor) => {
        let { currentWeek, testData, startDate, endDate } = this.state;
        currentWeek += factor;
        let date = this.getDateBeforeAfter(currentWeek * 7);
        let week = this.getWeek(new Date(date.getTime()));
        const testsOfTheWeek = this.getTestsOfWeek(week, testData);
        this.setState({
            week,
            currentWeek,
            currentMonth: this.months[date.getMonth()],
            currentYear: date.getFullYear(),
            testsOfTheWeek,
            bShowTestsOfWeek: true
        });
        let limitDate = week[0].date;
        if (factor > 0) {
            limitDate = week[6].date;
        }
        if (limitDate <= startDate || limitDate >= endDate) {
            const dateStr = commonFunctions.convertDateToString(limitDate);
            this.props.dispatch(testActions.getTestsByDate({ date: dateStr }))
        }
    };

    displayPlanedTest() {
        const { activeDate, testsOfTheWeek, bShowTestsOfWeek } = this.state;
        const retData = [];
        let dates = Object.keys(testsOfTheWeek);
        if (!bShowTestsOfWeek) {
            let dateStr = commonFunctions.convertDateToString(activeDate);
            dates = [dateStr];
        }
        if (dates) {
            for (let i = 0; i < dates.length; i++) {
                const tests = testsOfTheWeek[dates[i]];
                for (let j = 0; j < tests.length; j++) {
                    const test = tests[j];
                    retData.push(
                        <div key={`tests-${j}`} className="planned-box" onClick={e => this.onClickTest(test._id)}>
                            <div className="inner-planned-box">
                                <div className="float-left pr-2 planned-text">
                                    <h5>{test.name}</h5>
                                    {
                                        test.startTime && test.endTime &&
                                        <p>{test.startTime} - {test.endTime}</p>
                                    }
                                </div>
                                <div className="float-right pl-2 ellipsis">
                                    <a href="#">
                                        <MoreHorizIcon />
                                    </a>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        }
        return retData;
    }

    render() {
        const { t } = this.props;
        const { currentMonth, currentYear, bShowTestsOfWeek } = this.state;
        return (
            <div className="rightbar">
                <div className="w-100 rightbar-inner-container">
                    <div className="d-block right-calendar">
                        <div className="d-block calendar-heading">
                            <h4 className="d-inline-block mb-0">{t(currentMonth)} {currentYear}</h4>
                            <div className="float-right">
                                <a onClick={e => { this.changeCurrentWeek(e, -1) }} className="btn rounded-circle d-inline-block align-top mr-2 text-center">
                                    <ChevronLeftIcon />
                                </a>
                                <a onClick={e => { this.changeCurrentWeek(e, 1) }} className="btn rounded-circle d-inline-block align-top text-center">
                                    <ChevronRightIcon />
                                </a>
                            </div>
                        </div>
                        <div className="calendar-days-table">
                            {this.displayWeek()}
                        </div>
                    </div>
                    <div className="d-block pb-5 planned">
                        <div className="d-block planned-heading">
                            <h4 className="d-inline-block mb-0">{t("Planned tests")}</h4>
                            {
                                !bShowTestsOfWeek &&
                                <div className="float-right">
                                    <a onClick={this.onClickViewAll} className="d-inline-block align-top">{t("View all")}</a>
                                </div>
                            }
                        </div>
                        <Scrollbars style={{ height: "calc( 100% - 250px )" }}>
                            <div>
                                {this.displayPlanedTest()}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { get_test_by_date_status, testsByDateData } = state.test;
    return {
        get_test_by_date_status,
        testsByDateData
    };
}

const connectedCalendarView = withTranslation()(connect(mapStateToProps)(CalendarView));
export { connectedCalendarView as CalendarView };
