import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { CustomTooltip } from "../../_components";

class LineScaleAllSamples extends Component {
    ref = null;
    colors = ["#ADCADB", "#8CAABB", "#6E8998", "#3B6F8D", "#255772"];
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    renderSummaryHeading() {
        const { t, question, index, noOfParticipants, totalConsumers } = this.props;
        return (
            <div className="d-block heading">
                <div className="d-inline-block align-top heading-number" style={{ backgroundColor: "#4C7095" }}>{index}</div>
                <div className="d-inline-block align-top heading-text">
                    <label>{question}</label>
                    <p>{totalConsumers} {t('out of')} {noOfParticipants} {t("answered this question")}</p>
                </div>
            </div>
        );
    }

    renderSummaryGridSamples() {
        const { summary, index } = this.props;
        const retData = [];
        for (let i = 0; i < summary.length; i++) {
            const data = summary[i];
            retData.push(
                <div key={`${i}-line-scale-sample-${index}`} className="d-inline-block sample-text">
                    <span className="d-inline-block" style={{ backgroundColor: this.colors[i] }}></span>
                    <label>{data.sample}</label>
                </div>
            );
        }
        return retData;
    }

    renderSummaryLines() {
        const retData = [];
        const { summary, index, totalConsumers, showPercentage } = this.props;
        let opts = [];
        let summ = [];
        if (summary && summary.length > 0) {
            opts = summary[0].question.opts;
            summ = summary[0].question.summ;
        }
        let showTooltip = summary.length > 3;
        for (let i = 0; i < summ.length; i++) {
            let graphLines = [];
            for (let j = 0; j < summary.length; j++) {
                const ans = summary[j].question.summ[i];
                const percentage = parseFloat((ans / totalConsumers) * 100).toFixed(1);
                graphLines.push(
                    <div key={`line-scale-sample-graph-line-${j}-${index}`} className="d-flex align-content-end graph">
                        <div className="d-flex align-content-end flex-wrap graph-inner">
                            {(showPercentage || !showTooltip) && <label>{parseInt(percentage) !== 0 ? `${percentage}%` : ""}</label>}
                            {
                                showTooltip &&
                                <CustomTooltip placement="top" title={parseInt(percentage) !== 0 ? `${percentage}%` : ""}>
                                    <span style={{ backgroundColor: this.colors[j], height: percentage + '%' }}>{percentage}%</span>
                                </CustomTooltip>
                            }
                            {
                                !showTooltip &&
                                <span style={{ backgroundColor: this.colors[j], height: percentage + '%' }}>{percentage}%</span>
                            }
                        </div>
                    </div>
                );
            }
            retData.push(
                <div key={`line-scale-sample-graph-${i}-${index}`} className="col" style={{ width: '9.090909090%', paddingLeft: '0px', paddingRight: '0px' }}>
                    <div className="d-flex align-content-end justify-content-center graph-lines">
                        {graphLines}
                    </div>
                </div>
            )
        }
        return retData;
    };

    renderSummaryTexts() {
        const { summary, index } = this.props;
        let opts = [];
        if (summary && summary.length > 0) {
            opts = summary[0].question.opts;
        }
        const retData = [];
        retData.push(
            <div key={`line-scale-sample-graph-text-${index}`} className="col" style={{ width: '9.090909090%', paddingRight: '0px', paddingLeft: '0px' }}>
                <span></span>
                <p>{opts[0]}</p>
            </div>
        );
        for (let i = 1; i < 10; i++) {
            const data = opts[i];
            retData.push(
                <div key={`line-scale-sample-graph-text-${i}-${index}`} className="col" style={{ width: '9.090909090%', paddingRight: '0px', paddingLeft: '0px' }}>
                    <span></span>
                    <p>{i * 10}</p>
                </div>
            );
        }
        retData.push(
            <div key={`line-scale-sample-graph-text-${index}`} className="col strong" style={{ width: '9.090909090%', paddingRight: '0px', paddingLeft: '0px' }}>
                <span></span>
                <p>{opts[1]}</p>
            </div>
        );
        return retData;
    };

    getChartHeight = () => {
        const lineScaleAllHeight = this.ref.current.clientHeight;
        return lineScaleAllHeight;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="question-container" ref={this.ref}>
                {this.renderSummaryHeading()}
                <div className="d-block question-inner">
                    <div className="d-block grid-graph-container">
                        <div className="d-block sample-text-blocks">
                            {this.renderSummaryGridSamples()}
                        </div>
                        <div className="d-block grid-graph-scrollbar line-scale-graph-scrollbar" style={{ height: '330px', paddingLeft: '15px', marginLeft: '-15px' }}>
                            <div className="d-flex align-content-end flex-wrap grid-graph" style={{ backgroundColor: 'rgba(221, 228, 237, 1)', width: '100%' }}>
                                <div class="row" style={{ width: '100%', marginRight: '0px', marginLeft: '0px' }}>
                                    {this.renderSummaryLines()}
                                </div>
                            </div>
                            <div className="d-block line-scale-graph-text" style={{ width: '100%' }}>
                                <div className="row" style={{ width: '100%', marginLeft: '0px', marginRight: '0px' }}>
                                    {this.renderSummaryTexts()}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const translatedLineScaleAllSamples = withTranslation('', { withRef: true })(LineScaleAllSamples);
export { translatedLineScaleAllSamples as LineScaleAllSamples };