import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEng from "./translations/en.json";
import translationNo from "./translations/no.json";
i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: false,
        lng: "en",
        fallbackLng: "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translations: translationEng
            },
            no: {
                translations: translationNo
            }
        },
        ns: ["translations"],
        defaultNS: "translations",
        nsSeparator: "|"
    });

export default i18n;
