import { authConstants } from '../_constants';
import { authServices } from '../_services';
import { alert, commonFunctions } from '../_utilities';

export const authActions = {
    login,
    register,
    checkEmail,
    checkCompany,
    verifyEmail,
    resendEmail,
    logOut,
    registerMember,
    forgotPassword,
    resetPassword
};

function login(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: authConstants.USER_LOGIN_REQUEST,
            data: null
        }));
        authServices.login(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: authConstants.USER_LOGIN_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: authConstants.USER_LOGIN_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: authConstants.USER_LOGIN_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function register(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: authConstants.REGISTER_USER_REQUEST,
            data: null
        }));
        authServices.register(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: authConstants.REGISTER_USER_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: authConstants.REGISTER_USER_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: authConstants.REGISTER_USER_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function registerMember(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: authConstants.REGISTER_MEMBER_REQUEST,
            data: null
        }));
        authServices.registerMember(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: authConstants.REGISTER_MEMBER_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: authConstants.REGISTER_MEMBER_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: authConstants.REGISTER_MEMBER_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function verifyEmail(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: authConstants.VERIFY_EMAIL_REQUEST,
            data: null
        }));
        authServices.verifyEmail(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: authConstants.VERIFY_EMAIL_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: authConstants.VERIFY_EMAIL_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: authConstants.VERIFY_EMAIL_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function checkEmail(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: authConstants.CHECK_EMAIL_REQUEST,
            data: null
        }));
        authServices.checkEmail(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: authConstants.CHECK_EMAIL_SUCCESS,
                            data: {
                                ...data,
                                ...response.data
                            }
                        }));
                    } else {
                        if (response && response.error && response.error.status === 403) {
                            dispatch(dispatchFunction({
                                type: authConstants.CHECK_EMAIL_SUCCESS,
                                data: {
                                    ...data,
                                    emailBlocked: true,
                                    message: response.message
                                }
                            }));
                        } else {
                            dispatch(dispatchFunction({
                                type: authConstants.CHECK_EMAIL_FAILURE,
                                data: null
                            }));
                            alert.error(response.message);
                        }
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: authConstants.CHECK_EMAIL_FAILURE,
                        data: null
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function checkCompany(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: authConstants.CHECK_COMPANY_REQUEST,
            data: null
        }));
        authServices.checkCompany(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: authConstants.CHECK_COMPANY_SUCCESS,
                            data: {
                                ...data,
                                ...response.data
                            }
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: authConstants.CHECK_COMPANY_FAILURE,
                            data: null
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: authConstants.CHECK_COMPANY_FAILURE,
                        data: null
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function resendEmail(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: authConstants.RESEND_EMAIL_REQUEST,
            data: null
        }));
        authServices.resendEmail(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: authConstants.RESEND_EMAIL_SUCCESS,
                            data: response.data
                        }));
                        alert.success("Email send successfully");
                    } else {
                        dispatch(dispatchFunction({
                            type: authConstants.RESEND_EMAIL_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: authConstants.RESEND_EMAIL_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function forgotPassword(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: authConstants.FORGOT_PASSWORD_REQUEST,
            data: null
        }));
        authServices.forgotPassword(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: authConstants.FORGOT_PASSWORD_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: authConstants.FORGOT_PASSWORD_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: authConstants.FORGOT_PASSWORD_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function resetPassword(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: authConstants.RESET_PASSWORD_REQUEST,
            data: null
        }));
        authServices.resetPassword(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: authConstants.RESET_PASSWORD_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: authConstants.RESET_PASSWORD_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: authConstants.RESET_PASSWORD_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function logOut() {
    commonFunctions.onLogout();
    return dispatch => {
        dispatch(dispatchFunction({
            type: authConstants.USER_LOGOUT,
            data: null
        }));
    };
}

function dispatchFunction(data) {
    if(data.data && data.data.code === 401){
        commonFunctions.onLogout();
        return {
            type: authConstants.USER_LOGOUT,
            data: null
        };
    }
    return {
        type: data.type,
        data: data.data
    };
}