import { userConstants, authConstants } from '../_constants';
import { userServices } from '../_services';
import { alert , commonFunctions} from '../_utilities';

export const userActions = {
    inviteUsers,
    getUsersList
};

function inviteUsers(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: userConstants.INVITE_USERS_REQUEST,
            data: null
        }));
        userServices.inviteUsers(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: userConstants.INVITE_USERS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: userConstants.INVITE_USERS_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: userConstants.INVITE_USERS_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function getUsersList(data) {
    return dispatch => {
        dispatch(dispatchFunction({
            type: userConstants.GET_USERS_LIST_REQUEST,
            data: null
        }));
        userServices.getUsersList(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: userConstants.GET_USERS_LIST_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: userConstants.GET_USERS_LIST_FAILURE,
                            data: response
                        }));
                        alert.error(response.message);
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: userConstants.GET_USERS_LIST_FAILURE,
                        data: error.message
                    }));
                    alert.error(error.message);
                }
            );
    };
}

function dispatchFunction(data) {
    if(data.data && data.data.code === 401){
        commonFunctions.onLogout();
        return {
            type: authConstants.USER_LOGOUT,
            data: null
        };
    }
    return {
        type: data.type,
        data: data.data
    };
}