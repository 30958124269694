import config from '../config';
import { commonFunctions } from "../_utilities";

export const userServices = {
    inviteUsers,
    getUsersList
}

function inviteUsers(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data));
    return fetch(`${config.apiUrl}/api/auth/invite`, requestOptions).then(response => response.json());
}

function getUsersList(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null);
    return fetch(`${config.apiUrl}/api/user/allusers?skip=${data.skip}&limit=${data.limit}`, requestOptions).then(response => response.json());
}