import React from 'react';

const Dashboard = React.lazy(() => import('../PostLogin/Dashboard'));
const CreateTest = React.lazy(() => import('../PostLogin/CreateTest'));
const ViewTest = React.lazy(() => import('../PostLogin/ViewTest'));
const TestList = React.lazy(() => import('../PostLogin/TestList'));
const AddQuestionnaires = React.lazy(() => import('../PostLogin/AddQuestionnaires'));
const ViewQuestionnaire = React.lazy(() => import('../PostLogin/AddQuestionnaires/ViewQuestionnaire'));
const QuestionnaireList = React.lazy(() => import('../PostLogin/QuestionnaireList'));
const Answers = React.lazy(() => import('../PostLogin/Answers'));
const Summary = React.lazy(() => import('../PostLogin/Summary'));

const routes = [
    { path: '/postlogin/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/postlogin/createtest', exact: true, name: 'Create Test', component: CreateTest },
    { path: '/postlogin/createtest/:testId', exact: true, name: 'Create Test', component: CreateTest },
    { path: '/postlogin/viewtest/:testId', exact: true, name: 'View Test', component: ViewTest },
    { path: '/postlogin/testlist', exact: true, name: 'Test List', component: TestList },
    { path: '/postlogin/questionnaires/add/:testName/:testId', exact: true, name: 'AddQuestionnaires', component: AddQuestionnaires },
    { path: '/postlogin/questionnaires/view/:testName/:testId', exact: true, name: 'ViewQuestionnaire', component: ViewQuestionnaire },
    { path: '/postlogin/questionnairelist', exact: true, name: 'QuestionnaireList', component: QuestionnaireList },
    { path: '/postlogin/answers/:testId', exact: true, name: 'Answers', component: Answers },
    { path: '/postlogin/summary/:testId', exact: true, name: 'Summary', component: Summary },
];

export default routes;