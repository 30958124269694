import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#313349",
    },
    tooltip: {
        backgroundColor: "#313349",
        fontFamily: "Open Sans",
        fontWeight: "500",
        color: "#F5F5F5",
        fontSize: 11
    },
}));

export function CustomTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}