export default {
  basePath: "/",
  printSummaryURL:
    process.env.NODE_ENV === "development"
      ? "http://62.72.13.79:4040/api"
      : "http://62.72.13.79:4040/api",
  apiUrl:
    process.env.NODE_ENV === "development"
      ? "http://62.72.13.79:4000"
      : "http://62.72.13.79:4000",
  adminUrl:
    process.env.NODE_ENV === "development"
      ? "http://62.72.13.79:5001/api/admin"
      : "http://62.72.13.79:5001/api/admin",
  printPage:
    process.env.NODE_ENV === "development"
      ? "http://tryio-business.infoniumtech.com/"
      : "http://tryio-business.infoniumtech.com",
  // printSummaryURL: "https://appcommon.tryio.no",
  // apiUrl: "https://businessbackend.tryio.no",
  // adminUrl: "https://adminbackend.tryio.no/api/admin",
  enableGA: true,
};
