import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

class LineScale extends Component {
    ref = null;
    constructor(props) {
        super(props);
        this.state = {}
        this.ref = React.createRef();
    }

    renderSummaryHeading() {
        const { t, question, index, noOfParticipants, totalConsumers } = this.props;
        return (
            <div className="d-block heading">
                <div className="d-inline-block align-top heading-number" style={{ backgroundColor: "#4C7095" }}>{index}</div>
                <div className="d-inline-block align-top heading-text">
                    <label>{question}</label>
                    <p>{totalConsumers} {t('out of')} {noOfParticipants} {t("answered this question")}</p>
                </div>
            </div>
        );
    }

    renderSummaryLines() {
        const { summ, index, totalConsumers, showPercentage } = this.props;
        const retData = [];
        if (summ && summ.length > 0) {
            for (let i = 0; i < summ.length; i++) {
                let data = summ[i];
                const percentage = parseFloat((data / totalConsumers) * 100).toFixed(1);
                retData.push(
                    <div key={`${i}-linescale-bar-${index}`} className="col" style={{ width: '9.090909090%', paddingRight: '7px', paddingLeft: '7px' }}>
                        <div className="d-flex align-content-end flex-wrap graph-lines">
                            <div className="d-flex align-content-end flex-wrap graph" style={{ width: '100%' }}>
                                <div className="d-flex align-content-end flex-wrap graph-inner">
                                    {showPercentage && <label>{parseInt(percentage) !== 0 ? `${percentage}%` : ""}</label>}
                                    <span style={{ backgroundColor: "rgba(76, 112, 149, 1)", height: percentage + '%' }}>{parseInt(percentage) !== 0 ? `${percentage}%` : ""}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        return retData;
    };

    renderSummaryTexts() {
        const { opts, summ, index } = this.props;
        const retData = [];
        retData.push(
            <div key={`${index}-linescale-first-line`} className="col" style={{ width: '9.090909090%', paddingRight: '0px', paddingLeft: '0px' }}>
                <span></span>
                <p>{opts[0]}</p>
            </div>
        );
        for (let i = 1; i < summ.length; i++) {
            retData.push(
                <div key={`${i}-linescale-${index}`} className="col" style={{ width: '9.090909090%', paddingRight: '0px', paddingLeft: '0px' }}>
                    <span></span>
                    <p>{(i) * 10}</p>
                </div>
            );
        }
        retData.push(
            <div className="col strong" style={{ width: '9.090909090%', paddingRight: '0px', paddingLeft: '0px' }}>
                <span></span>
                <p>{opts[1]}</p>
            </div>
        );
        return retData;
    };

    getChartHeight = () => {
        const lineScaleHeight = this.ref.current.clientHeight;
        return lineScaleHeight;
    }

    render() {
        return (
            <div className="question-container" ref={this.ref}>
                {this.renderSummaryHeading()}
                <div className="d-block question-inner">
                    <div className="d-block grid-graph-container">
                        <div className="d-block grid-graph-scrollbar line-scale-graph-scrollbar" style={{ height: '345px', paddingLeft: '15px', marginLeft: '-15px' }}>
                            <div className="d-flex align-content-end flex-wrap grid-graph" style={{ backgroundColor: 'rgba(222, 228, 237, 0.70)', width: '100%' }}>
                                <div class="row" style={{ width: '100%', marginRight: '0px', marginLeft: '0px' }}>
                                    {this.renderSummaryLines()}
                                </div>
                            </div>
                            <div className="d-block line-scale-graph-text" style={{ width: '100%' }}>
                                <div className="row" style={{ width: '100%', marginLeft: '0px', marginRight: '0px' }}>
                                    {this.renderSummaryTexts()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const translatedLineScale = withTranslation('', { withRef: true })(LineScale);
export { translatedLineScale as LineScale };