export const authConstants = {
    USER_LOGOUT: 'USER_LOGOUT',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
    
    REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
    REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
    REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',

    REGISTER_MEMBER_REQUEST: 'REGISTER_MEMBER_REQUEST',
    REGISTER_MEMBER_SUCCESS: 'REGISTER_MEMBER_SUCCESS',
    REGISTER_MEMBER_FAILURE: 'REGISTER_MEMBER_FAILURE',

    CHECK_EMAIL_REQUEST: 'CHECK_EMAIL_REQUEST',
    CHECK_EMAIL_SUCCESS: 'CHECK_EMAIL_SUCCESS',
    CHECK_EMAIL_FAILURE: 'CHECK_EMAIL_FAILURE',

    CHECK_COMPANY_REQUEST: 'CHECK_COMPANY_REQUEST',
    CHECK_COMPANY_SUCCESS: 'CHECK_COMPANY_SUCCESS',
    CHECK_COMPANY_FAILURE: 'CHECK_COMPANY_FAILURE',

    VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',

    RESEND_EMAIL_REQUEST: 'RESEND_EMAIL_REQUEST',
    RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
    RESEND_EMAIL_FAILURE: 'RESEND_EMAIL_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
};