import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './../../_assets/scss/sidebar.scss';
import HomeIcon from '@material-ui/icons/Home';
import { withTranslation } from "react-i18next";
import FolderOpenSharpIcon from '@material-ui/icons/FolderOpenSharp';
import ListAltIcon from '@material-ui/icons/ListAlt';
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
        };
    }

    LINKS = [
        { url: '/postlogin/dashboard', active: 0 },
        { url: '/postlogin/testlist', active: 1 }, 
        { url: '/postlogin/createtest', active: 1 }, 
        { url: '/postlogin/viewtest', active: 1 },
        { url: '/postlogin/questionnairelist', active: 2 },
        { url: '/postlogin/questionnaires', active: 2 },
        { url: '/postlogin/answers', active: 2 },
        { url: '/postlogin/summary', active: 2 },
    ];

    componentDidMount() {
        const { history } = this.props;
        this.historyListener = history.listen((location) => {
            this.changeActiveTab(location);
        });
        this.changeActiveTab(this.props.location);
    }

    changeActiveTab = (location) => {
        const pathname = location.pathname;
        for(let i=0; i< this.LINKS.length; i++){
            if(pathname.indexOf(this.LINKS[i].url) !== -1){
                this.setState({
                    activeTab: this.LINKS[i].active
                });
                break;
            }
        }
    };

    componentWillUnmount() {
        if (this.historyListener) {
            this.historyListener();
        }
    }

    setActiveTab = (activeTab) => {
        this.setState({
            activeTab
        });
    };

    render() {
        const { activeTab } = this.state;
        const { t } = this.props;
        return (
            <div className="sidebar">
                <ul className="d-block mb-0 pl-0 pt-3 w-100 sidebar-menu">
                    <li className={activeTab === 0 ? "d-block pb-2 active-page" : "d-block pb-2"} onClick={e => this.setActiveTab(0)}>
                        <Link to="/postlogin/dashboard" className="d-block text-center">
                            {/* <i className="d-inline-block icon icon-home"></i> */}
                            <HomeIcon fontSize="large" />
                            <span className="d-block">{t("Home")}</span>
                        </Link>
                    </li>
                    <li className={activeTab === 1 ? "d-block pb-2 active-page" : "d-block pb-2"} onClick={e => this.setActiveTab(1)}>
                        <Link to="/postlogin/testlist" className="d-block text-center">
                            <FolderOpenSharpIcon fontSize="large" />
                            <span className="d-block">{t("Tests")}</span>
                        </Link>
                    </li>
                    <li className={activeTab === 2 ? "d-block pb-2 active-page" : "d-block pb-2"} onClick={e => this.setActiveTab(2)}>
                        <Link to="/postlogin/questionnairelist" className="d-block text-center">
                            <ListAltIcon fontSize="large" />
                            <span className="d-block">{t("Questionnaires")}</span>
                        </Link>
                    </li>
                </ul>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

const connectedSidebar = withTranslation()(connect(mapStateToProps)(Sidebar));
export { connectedSidebar as Sidebar };
