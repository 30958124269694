import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { CircularProgressbar } from 'react-circular-progressbar';
import { Scrollbars } from 'react-custom-scrollbars';

class InsightsBar extends Component {
    constructor(props) {
        super(props);
    }

    getGender = (gender) => {
        if (gender === "B") {
            return "Both";
        } else if (gender === "M") {
            return "Male";
        }
        return "Female";
    };

    renderTestSessions = (testDetails) => {
        const retData = [];
        const { t } = this.props;
        if (testDetails && testDetails.type === "location") {
            for (let i = 0; i < 2; i++) {
                retData.push(
                    <div className={`d-block test-info-box session-${i % 2}`}>
                        <div className="d-inline-block left-part">
                            <div className="heading">{t("SESSION")} {i + 1}</div>
                            <div className="user-info">{t("Requested testers")}: 60</div>
                            <div className="user-info">{t("Recieved responses")}: 51</div>
                        </div>
                        <div className="d-inline-block right-part">
                            <CircularProgressbar value={60} text={`${60}%`} strokeWidth={5} styles={{
                                trail: { opacity: "0.5", stroke: "#FFF" }, text: { fill: "#FFF", fontSize: "19px", fontFamily: "Arial", fontWeight: "600" }, path: { stroke: "#FFF", strokeLinecap: "round", }
                            }} />
                        </div>
                    </div>
                );
            }
        }
        return retData;
    };

    renderHomeTestCard = (testDetails) => {
        const { t } = this.props;
        const retData = [];
        if (testDetails && testDetails.type === "home") {
            const percentage = parseInt(testDetails.noOfParticipants * 100 / testDetails.totalNumberOfConsumers);
            retData.push(
                <div key={`home-card`} className={`d-block test-info-box session-0`}>
                    <div className="d-inline-block left-part">
                        <div className="user-info">{t('Requested testers')}: {testDetails.totalNumberOfConsumers}</div>
                        <div className="user-info">{t('Recieved responses')}: {testDetails.noOfParticipants}</div>
                    </div>
                    <div className="d-inline-block right-part">
                        <CircularProgressbar value={percentage} text={`${percentage}%`} strokeWidth={5} styles={{
                            trail: { opacity: "0.5", stroke: "#FFF" }, text: { fill: "#FFF", fontSize: "19px", fontFamily: "Arial", fontWeight: "600" }, path: { stroke: "#FFF", strokeLinecap: "round", }
                        }} />
                    </div>
                </div>
            );
        }
        return retData;
    };

    render() {
        const { t, testDetails } = this.props;
        return (
            <div className="insight-rightbar rightbar">
                <div className="d-block w-100 insight-container">
                    <div className="d-block heading">{t("Insight")}</div>
                    <Scrollbars style={{ height: "calc( 100% - 120px )" }}>
                        {
                            testDetails._id &&
                            <div className="d-block insight-box">
                                <ul>
                                    <li className="d-block">
                                        <label className="d-inline-block label">{t("Test type")}:</label>
                                        <label className="d-inline-block value">{testDetails.type === "home" ? t('Home testing') : t('Location testing')}</label>
                                    </li>
                                    <li className="d-block">
                                        <label className="d-inline-block label">{t("Test ID")}:</label>
                                        <label className="d-inline-block value">{testDetails.testId}</label>
                                    </li>
                                    <li className="d-block">
                                        <label className="d-inline-block label">{t("Age range")}:</label>
                                        <label className="d-inline-block value">{testDetails.ageRange.startAge} - {testDetails.ageRange.endAge}</label>
                                    </li>
                                    <li className="d-block">
                                        <label className="d-inline-block label">{t("Gender")}:</label>
                                        <label className="d-inline-block value">{this.getGender(testDetails.gender)}</label>
                                    </li>
                                </ul>
                            </div>
                        }
                        {this.renderTestSessions(testDetails)}
                        {this.renderHomeTestCard(testDetails)}
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

const translatedInsightsBar = withTranslation('', { withRef: true })(InsightsBar);

export { translatedInsightsBar as InsightsBar };