import config from '../config';
import { commonFunctions } from "../_utilities";

export const authServices = {
    login,
    register,
    checkEmail,
    checkCompany,
    verifyEmail,
    resendEmail,
    registerMember,
    forgotPassword,
    resetPassword
}

function login(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data), true);
    return fetch(`${config.apiUrl}/api/auth/login`, requestOptions).then(response => response.json());
}

function register(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data), true);
    return fetch(`${config.apiUrl}/api/auth/signup`, requestOptions).then(response => response.json());
}

function registerMember(data) {
    const extraHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${data.token}`
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data), true);
    return fetch(`${config.apiUrl}/api/auth/signup/member`, requestOptions).then(response => response.json());
}

function verifyEmail(data) {
    const extraHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${data}`
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null, true);
    return fetch(`${config.apiUrl}/api/auth/verifyemail`, requestOptions).then(response => response.json());
}

function checkEmail(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data), true);
    return fetch(`${config.apiUrl}/api/auth/checkemail`, requestOptions).then(response => response.json());
}

function checkCompany(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data), true);
    return fetch(`${config.apiUrl}/api/auth/checkorganization`, requestOptions).then(response => response.json());
}

function resendEmail(data) {
    const extraHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${data}`
    };
    const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders, null, true);
    return fetch(`${config.apiUrl}/api/auth/resendemail`, requestOptions).then(response => response.json());
}

function forgotPassword(data) {
    const extraHeaders = {
        "Content-Type": "application/json"
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data), true);
    return fetch(`${config.apiUrl}/api/auth/resetpassword/sendlink`, requestOptions).then(response => response.json());
}

function resetPassword(data) {
    const extraHeaders = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${data.token}`
    };
    const requestOptions = commonFunctions.getRequestOptions("POST", extraHeaders, JSON.stringify(data), true);
    return fetch(`${config.apiUrl}/api/auth/resetpassword/verify`, requestOptions).then(response => response.json());
}