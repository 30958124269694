import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

class Jar extends Component {
    ref = null;
    constructor(props) {
        super(props);
        this.state = {
        }
        this.ref = React.createRef();
    }

    renderSummaryHeading() {
        const { t, question, index, noOfParticipants, totalConsumers } = this.props;
        return (
            <div className="d-block heading">
                <div className="d-inline-block align-top heading-number" style={{ backgroundColor: "#BF9677" }}>{index}</div>
                <div className="d-inline-block align-top heading-text">
                    <label>{question}</label>
                    <p>{totalConsumers} {t('out of')} {noOfParticipants} {t("answered this question")}</p>
                </div>
            </div>
        );
    }

    renderSummaryLines() {
        const { summ, index, totalConsumers, showPercentage } = this.props;
        const retData = [];
        if (summ && summ.length > 0) {
            for (let i = 0; i < summ.length; i++) {
                const data = summ[i];
                const percentage = parseFloat((data.cnt / totalConsumers) * 100).toFixed(1);
                retData.push(
                    <div key={`${i}-jarScale-${index}`} className="d-flex align-content-end flex-wrap jar-graph">
                        <div className="d-flex align-content-end flex-wrap jar-graph-inner">
                            {showPercentage && <div className="text">{parseInt(percentage) !== 0 ? `${percentage}%` : ""}</div>}
                            <div className="graph" style={{ backgroundColor: "rgba(191, 150, 119, 1)", height: percentage + '%' }}>{percentage}%</div>
                            <div className="bottom-text">
                                {this.renderSummaryText(i)}
                            </div>
                        </div>
                    </div>
                );
            }
        }
        return retData;
    };

    renderSummaryText = (index) => {
        const { opts } = this.props;
        const retData = [];
        for (let i = 0; i < opts.length; i++) {
            const data = opts[i];
            if (i == index) {
                retData.push(
                    <span className="d-block">{data}</span>
                );
            }
        }
        return retData;
    }

    getChartHeight = () => {
        const jarHeight = this.ref.current.clientHeight;
        return jarHeight;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="question-container" ref={this.ref}>
                {this.renderSummaryHeading()}
                <div className="d-block question-inner">
                    <div className="d-block jar-graph-scrollbar">
                        <div className="d-flex jar-graph-container" style={{ backgroundColor: 'rgba(191, 150, 119, 0.20)', width: '100%' }}>
                            {this.renderSummaryLines()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const translatedJar = withTranslation('', { withRef: true })(Jar);
export { translatedJar as Jar };