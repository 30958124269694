export const questionnaireConstants = {
    ADD_QUESTIONNAIRE_REQUEST: 'ADD_QUESTIONNAIRE_REQUEST',
    ADD_QUESTIONNAIRE_SUCCESS: 'ADD_QUESTIONNAIRE_SUCCESS',
    ADD_QUESTIONNAIRE_FAILURE: 'ADD_QUESTIONNAIRE_FAILURE',

    GET_INDIVIDUAL_QUESTIONNAIRE_REQUEST: 'GET_INDIVIDUAL_QUESTIONNAIRE_REQUEST',
    GET_INDIVIDUAL_QUESTIONNAIRE_SUCCESS: 'GET_INDIVIDUAL_QUESTIONNAIRE_SUCCESS',
    GET_INDIVIDUAL_QUESTIONNAIRE_FAILURE: 'GET_INDIVIDUAL_QUESTIONNAIRE_FAILURE',

    GET_RESPONSES_QUESTIONNAIRE_REQUEST: 'GET_RESPONSES_QUESTIONNAIRE_REQUEST',
    GET_RESPONSES_QUESTIONNAIRE_SUCCESS: 'GET_RESPONSES_QUESTIONNAIRE_SUCCESS',
    GET_RESPONSES_QUESTIONNAIRE_FAILURE: 'GET_RESPONSES_QUESTIONNAIRE_FAILURE',

    GET_TEST_SUMMARY_REQUEST: 'GET_TEST_SUMMARY_REQUEST',
    GET_TEST_SUMMARY_SUCCESS: 'GET_TEST_SUMMARY_SUCCESS',
    GET_TEST_SUMMARY_FAILURE: 'GET_TEST_SUMMARY_FAILURE',

    GET_TEST_SUMMARY_PRINT_REQUEST: 'GET_TEST_SUMMARY_PRINT_REQUEST',
    GET_TEST_SUMMARY_PRINT_SUCCESS: 'GET_TEST_SUMMARY_PRINT_SUCCESS',
    GET_TEST_SUMMARY_PRINT_FAILURE: 'GET_TEST_SUMMARY_PRINT_FAILURE',

    GET_TEST_SUMMARY_FOR_SESSION_REQUEST: 'GET_TEST_SUMMARY_FOR_SESSION_REQUEST',
    GET_TEST_SUMMARY_FOR_SESSION_SUCCESS: 'GET_TEST_SUMMARY_FOR_SESSION_SUCCESS',
    GET_TEST_SUMMARY_FOR_SESSION_FAILURE: 'GET_TEST_SUMMARY_FOR_SESSION_FAILURE',

    GET_DOWNLOAD_REQUEST: 'GET_DOWNLOAD_REQUEST',
    GET_DOWNLOAD_SUCCESS: 'GET_DOWNLOAD_SUCCESS',
    GET_DOWNLOAD_FAILURE: 'GET_DOWNLOAD_FAILURE',

    PRINT_SUMMARY_REQUEST: 'PRINT_SUMMARY_REQUEST',
    PRINT_SUMMARY_SUCCESS: 'PRINT_SUMMARY_SUCCESS',
    PRINT_SUMMARY_FAILURE: 'PRINT_SUMMARY_FAILURE',
};