import { testConstants, status } from '../_constants';

export function test(state = {}, action) {
    switch (action.type) {
        case testConstants.CREATE_TEST_REQUEST:
            return {
                ...state,
                create_test_status: status.IN_PROGRESS,
                createdTestData: action.data
            };
        case testConstants.CREATE_TEST_SUCCESS:
            return {
                ...state,
                create_test_status: status.SUCCESS,
                createdTestData: action.data
            };
        case testConstants.CREATE_TEST_FAILURE:
            return {
                ...state,
                create_test_status: status.FAILURE,
                createdTestData: null
            };
        case testConstants.GET_ALL_TEST_REQUEST:
            return {
                ...state,
                get_all_test_status: status.IN_PROGRESS,
                allTestList: null
            };
        case testConstants.GET_ALL_TEST_SUCCESS:
            return {
                ...state,
                get_all_test_status: status.SUCCESS,
                allTestList: action.data
            };
        case testConstants.GET_ALL_TEST_FAILURE:
            return {
                ...state,
                get_all_test_status: status.FAILURE,
                allTestList: null
            };
        case testConstants.GET_ALL_QUESTIONNAIRE_REQUEST:
            return {
                ...state,
                get_all_questionnaire_status: status.IN_PROGRESS,
                allQuestionnaireList: null
            };
        case testConstants.GET_ALL_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                get_all_questionnaire_status: status.SUCCESS,
                allQuestionnaireList: action.data
            };
        case testConstants.GET_ALL_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                get_all_questionnaire_status: status.FAILURE,
                allQuestionnaireList: null
            };
        case testConstants.GET_INDIVIDUAL_TEST_REQUEST:
            return {
                ...state,
                get_individual_test_status: status.IN_PROGRESS,
                individualTestData: null
            };
        case testConstants.GET_INDIVIDUAL_TEST_SUCCESS:
            return {
                ...state,
                get_individual_test_status: status.SUCCESS,
                individualTestData: action.data
            };
        case testConstants.GET_INDIVIDUAL_TEST_FAILURE:
            return {
                ...state,
                get_individual_test_status: status.FAILURE,
                individualTestData: null
            };
        case testConstants.GET_DIETARY_TAGS_REQUEST:
            return {
                ...state,
                get_dietary_tags_status: status.IN_PROGRESS,
                dietaryTags: null
            };
        case testConstants.GET_DIETARY_TAGS_SUCCESS:
            return {
                ...state,
                get_dietary_tags_status: status.SUCCESS,
                dietaryTags: action.data
            };
        case testConstants.GET_DIETARY_TAGS_FAILURE:
            return {
                ...state,
                get_dietary_tags_status: status.FAILURE,
                dietaryTags: null
            };
        case testConstants.GET_ALERGENS_LIST_REQUEST:
            return {
                ...state,
                get_alergens_list_status: status.IN_PROGRESS,
                alergensList: null
            };
        case testConstants.GET_ALERGENS_LIST_SUCCESS:
            return {
                ...state,
                get_alergens_list_status: status.SUCCESS,
                alergensList: action.data
            };
        case testConstants.GET_ALERGENS_LIST_FAILURE:
            return {
                ...state,
                get_alergens_list_status: status.FAILURE,
                alergensList: null
            };
        case testConstants.GET_FAMILY_SITUATION_REQUEST:
            return {
                ...state,
                get_family_situation_status: status.IN_PROGRESS,
                familySituationData: null
            };
        case testConstants.GET_FAMILY_SITUATION_SUCCESS:
            return {
                ...state,
                get_family_situation_status: status.SUCCESS,
                familySituationData: action.data
            };
        case testConstants.GET_FAMILY_SITUATION_FAILURE:
            return {
                ...state,
                get_family_situation_status: status.FAILURE,
                familySituationData: null
            };
        case testConstants.GET_EDUCATION_LEVEL_REQUEST:
            return {
                ...state,
                get_education_level_status: status.IN_PROGRESS,
                educationLevelData: null
            };
        case testConstants.GET_EDUCATION_LEVEL_SUCCESS:
            return {
                ...state,
                get_education_level_status: status.SUCCESS,
                educationLevelData: action.data
            };
        case testConstants.GET_EDUCATION_LEVEL_FAILURE:
            return {
                ...state,
                get_education_level_status: status.FAILURE,
                educationLevelData: null
            };
        case testConstants.GET_INCOME_LEVEL_REQUEST:
            return {
                ...state,
                get_income_level_status: status.IN_PROGRESS,
                incomeLevelData: null
            };
        case testConstants.GET_INCOME_LEVEL_SUCCESS:
            return {
                ...state,
                get_income_level_status: status.SUCCESS,
                incomeLevelData: action.data
            };
        case testConstants.GET_INCOME_LEVEL_FAILURE:
            return {
                ...state,
                get_income_level_status: status.FAILURE,
                incomeLevelData: null
            };
        case testConstants.SET_NEW_CREATED_TEST_STATUS:
            return {
                ...state,
                newCreatedTest: action.data
            };
        case testConstants.DELETE_TEST_REQUEST:
            return {
                ...state,
                delete_test_status: status.IN_PROGRESS,
                deletedTestData: null
            };
        case testConstants.DELETE_TEST_SUCCESS:
            return {
                ...state,
                delete_test_status: status.SUCCESS,
                deletedTestData: action.data
            };
        case testConstants.DELETE_TEST_FAILURE:
            return {
                ...state,
                delete_test_status: status.FAILURE,
                deletedTestData: null
            };
        case testConstants.GET_TESTS_BY_DATE_REQUEST:
            return {
                ...state,
                get_test_by_date_status: status.IN_PROGRESS,
                testsByDateData: null
            };
        case testConstants.GET_TESTS_BY_DATE_SUCCESS:
            return {
                ...state,
                get_test_by_date_status: status.SUCCESS,
                testsByDateData: action.data
            };
        case testConstants.GET_TESTS_BY_DATE_FAILURE:
            return {
                ...state,
                get_test_by_date_status: status.FAILURE,
                testsByDateData: null
            };
        case testConstants.GET_TEST_GALLERY_REQUEST:
            return {
                ...state,
                get_test_gallary_status: status.IN_PROGRESS,
                testGallary: null
            };
        case testConstants.GET_TEST_GALLERY_SUCCESS:
            return {
                ...state,
                get_test_gallary_status: status.SUCCESS,
                testGallary: action.data
            };
        case testConstants.GET_TEST_GALLERY_FAILURE:
            return {
                ...state,
                get_test_gallary_status: status.FAILURE,
                testGallary: null
            };
        case testConstants.UPLOAD_GALLERY_IMAGE_REQUEST:
            return {
                ...state,
                upload_gallery_image_status: status.IN_PROGRESS,
                uploadImageData: null
            };
        case testConstants.UPLOAD_GALLERY_IMAGE_SUCCESS:
            return {
                ...state,
                upload_gallery_image_status: status.SUCCESS,
                uploadImageData: action.data
            };
        case testConstants.UPLOAD_GALLERY_IMAGE_FAILURE:
            return {
                ...state,
                upload_gallery_image_status: status.FAILURE,
                uploadImageData: null
            };
        case testConstants.EDIT_TEST_IMAGE_REQUEST:
            return {
                ...state,
                edit_test_image_status: status.IN_PROGRESS,
                editImageData: null
            };
        case testConstants.EDIT_TEST_IMAGE_SUCCESS:
            return {
                ...state,
                edit_test_image_status: status.SUCCESS,
                editImageData: action.data
            };
        case testConstants.EDIT_TEST_IMAGE_FAILURE:
            return {
                ...state,
                edit_test_image_status: status.FAILURE,
                editImageData: null
            };
        case testConstants.DOWNLOAD_PARTICIPANT_LIST_REQUEST:
            return {
                ...state,
                download_participant_list_status: status.IN_PROGRESS,
                participantListFile: null
            };
        case testConstants.DOWNLOAD_PARTICIPANT_LIST_SUCCESS:
            return {
                ...state,
                download_participant_list_status: status.SUCCESS,
                participantListFile: action.data
            };
        case testConstants.DOWNLOAD_PARTICIPANT_LIST_FAILURE:
            return {
                ...state,
                download_participant_list_status: status.FAILURE,
                participantListFile: null
            };
        case testConstants.TESTS_WITHOUT_QUESTIONNAIRE_REQUEST:
            return {
                ...state,
                tests_without_questionnaire_status: status.IN_PROGRESS,
                testsWithoutQuestionnaire: null
            };
        case testConstants.TESTS_WITHOUT_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                tests_without_questionnaire_status: status.SUCCESS,
                testsWithoutQuestionnaire: action.data
            };
        case testConstants.TESTS_WITHOUT_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                tests_without_questionnaire_status: status.FAILURE,
                testsWithoutQuestionnaire: null
            };
        case testConstants.GET_LOCATION_REQUEST:
            return {
                ...state,
                location_list_status: status.IN_PROGRESS,
                locationList: null
            };
        case testConstants.GET_LOCATION_SUCCESS:
            return {
                ...state,
                location_list_status: status.SUCCESS,
                locationList: action.data
            };
        case testConstants.GET_LOCATION_FAILURE:
            return {
                ...state,
                location_list_status: status.FAILURE,
                locationList: null
            };
        default:
            return state;
    }
}