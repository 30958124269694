import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

class SingleChoice extends Component {
    ref = null;
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    renderSummaryHeading() {
        const { t, question, index, noOfParticipants, totalConsumers } = this.props;
        return (
            <div className="d-block heading">
                <div className="d-inline-block align-top heading-number" style={{ backgroundColor: "#7F81B8" }}>{index}</div>
                <div className="d-inline-block align-top heading-text">
                    <label>{question}</label>
                    <p>{totalConsumers} {t('out of')} {noOfParticipants} {t("answered this question")}</p>
                </div>
            </div>
        );
    }

    renderSummaryLines() {
        const { opts, summ, index, totalConsumers, showPercentage } = this.props;
        const retData = [];
        if (summ && summ.length > 0) {
            for (let i = 0; i < summ.length; i++) {
                const data = summ[i];
                const percentage = parseFloat((data.cnt / totalConsumers) * 100).toFixed(1);
                retData.push(
                    <div key={`${i}-lineLength-single-choice-${index}`} className="d-flex line-container">
                        {showPercentage && <div className="d-inline-block align-top text">{parseInt(percentage) !== 0 ? `${percentage}%` : ""}</div>}
                        <div className="d-inline-block align-top line-box" style={{ backgroundColor: "rgba(218, 225, 248, 0.80)" }}>
                            <label style={{ backgroundColor: "#C0C0DC", width: percentage + '%' }}></label>
                            <span className="d-block">{data.ans}</span>
                        </div>
                    </div>
                );
            }
        }
        return retData;
    };

    getChartHeight = () => {
        const singleChoiceHeight = this.ref.current.clientHeight;
        return singleChoiceHeight;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="d-block question-container" ref={this.ref}>
                {this.renderSummaryHeading()}
                <div className="d-block question-inner">
                    <div className="d-block choice-graph-container">
                        {this.renderSummaryLines()}
                    </div>
                </div>
            </div>
        );
    }
}

const translatedSingleChoice = withTranslation('', { withRef: true })(SingleChoice);
export { translatedSingleChoice as SingleChoice };