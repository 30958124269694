import { userConstants, status } from '../_constants';

export function user(state = {}, action) {
    switch (action.type) {
        case userConstants.INVITE_USERS_REQUEST:
            return {
                ...state,
                invite_users_status: status.IN_PROGRESS,
                inviteUserData: action.data
            };
        case userConstants.INVITE_USERS_SUCCESS:
            return {
                ...state,
                invite_users_status: status.SUCCESS,
                inviteUserData: action.data
            };
        case userConstants.INVITE_USERS_FAILURE:
            return {
                ...state,
                invite_users_status: status.FAILURE,
                inviteUserData: null
            };
        case userConstants.GET_USERS_LIST_REQUEST:
            return {
                ...state,
                get_users_list_status: status.IN_PROGRESS,
                usersList: action.data
            };
        case userConstants.GET_USERS_LIST_SUCCESS:
            return {
                ...state,
                get_users_list_status: status.SUCCESS,
                usersList: action.data
            };
        case userConstants.GET_USERS_LIST_FAILURE:
            return {
                ...state,
                get_users_list_status: status.FAILURE,
                usersList: null
            };
        default:
            return state;
    }
}