import React, { Component } from 'react';
import StarIcon from '@material-ui/icons/Star';
import FavoriteIcon from '@material-ui/icons/Favorite';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { withTranslation } from "react-i18next";

class Rating extends Component {
    ref = null;
    constructor(props) {
        super(props);
        this.state = {};
        this.ref = React.createRef();
    }

    renderSummaryHeading() {
        const { t, question, index, noOfParticipants, totalConsumers } = this.props;
        return (
            <div className="d-block heading">
                <div className="d-inline-block align-top heading-number" style={{ backgroundColor: "#FE967A" }}>{index}</div>
                <div className="d-inline-block align-top heading-text">
                    <label>{question}</label>
                    <p>{totalConsumers} {t('out of')} {noOfParticipants} {t("answered this question")}</p>
                </div>
            </div>
        );
    }

    renderSummaryLines() {
        const { icon, opts, summ, index, totalConsumers, showPercentage } = this.props;
        const retData = [];
        if (summ && summ.length > 0) {
            let iconComp = <StarIcon />;
            if (icon === "smiley") {
                iconComp = <InsertEmoticonIcon />;
            } else if (icon === "heart") {
                iconComp = <FavoriteIcon />;
            }
            for (let i = 0; i < summ.length; i++) {
                const data = summ[i];
                const percentage = parseFloat((data.cnt / totalConsumers) * 100).toFixed(1);
                retData.push(
                    <div key={`${i}-rating-${index}`} className="d-flex align-content-end rating-graph">
                        <div className="d-flex align-content-end flex-wrap rating-graph-inner">
                            {showPercentage && <div className="text">{parseInt(percentage) !== 0 ? `${percentage}%` : ""}</div>}
                            <div className="graph" style={{ backgroundColor: "rgba(234, 156, 135, 1)", height: percentage + '%' }}>{data.cnt}%</div>
                            <div className="rating">
                                <span style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                    {i + 1} {iconComp}
                                </span>
                                {/* {this.renderIcon(i + 1, iconComp)} */}
                            </div>
                        </div>
                    </div>
                );
            }
        }
        return retData;
    };

    renderIcon = (total, icon) => {
        const retData = [];
        for (let i = 0; i < total; i++) {
            retData.push(<span className="d-inline-block">{icon}</span>);
        }
        return retData;
    };

    getChartHeight = () => {
        const ratingHeight = this.ref.current.clientHeight;
        return ratingHeight;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="question-container" ref={this.ref}>
                {this.renderSummaryHeading()}
                <div className="d-block question-inner">
                    <div className="d-block rating-graph-scrollbar">
                        <div className="d-flex rating-graph-container" style={{ backgroundColor: 'rgba(234, 156, 135, 0.20)', width: '100%' }}>
                            {this.renderSummaryLines()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const translatedRating = withTranslation('', { withRef: true })(Rating);
export { translatedRating as Rating };